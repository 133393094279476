import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset } from '@app-models';
import { Observable } from 'rxjs';
import { CoreApiService } from '../http';

@Injectable({
  providedIn: 'root'
})
export class AssetsHttpService extends CoreApiService {

  private readonly endpoint = 'asset/';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * Load all company assets by it's id.
   *
   * @param companyId Id of selected company.
   *
   * @returns Array of assets.
   */
  public getCompanyAssets(companyId: string): Observable<Asset[]> {
    return this.get<Asset[]>(`${this.endpoint}getAssetsbyCompanyId/${companyId}`);
  }
}
