import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderByDirection } from '@app-models';
import { Observable, of } from 'rxjs';
import { Teltonika } from '@app-store-entities';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class TeltonikaService {

  private teltonikaUrl = 'https://api.teltonika.lt/';

  //temporary approach until we agree on interceptors
  private headers = {};

  constructor(protected http: HttpClient) {

    const header = new HttpHeaders()
      .set('Authorization', `Bearer 562|KmtDDYH9Wcgd3CeTjeQzqrqNhAE0lsuV4H0Kl5pP`); // may be localStorage/sessionStorage
    this.headers = { headers: header };
  }

  public getAllTeltonikasCompanies() {
    return this.http.get<any>(this.teltonikaUrl + 'companies', this.headers).pipe(
      map(response => response.data)
    );
  }

  public getAllTeltonikas(
    sortColumn?: string,
    sortDirection: OrderByDirection = 'asc',
    limit?: number,
    page: number = 0,
    query?: string): Observable<Teltonika[]> {

    /*eslint-disable */
    const queryParams = this.generatePaginateQueryParams(
      {
        sort: sortColumn,
        order: sortDirection,
        per_page: limit,
        page: page,
        query: query
      });
    /*eslint-enable */

    return this.http.get<any>(this.teltonikaUrl + 'devices?' + queryParams, this.headers)
      .pipe(
        map(response => response.data)
      );
  }

  /**
   * Temporary helpers method until table wrapper component is complete
   *
   * @param paramsObj
   * @returns
   */
  public generatePaginateQueryParams(paramsObj): HttpParams {
    // create copy of config object so changes will be avoided
    const queryParams = { ...paramsObj };

    let params = new HttpParams();
    for (const prop in queryParams) {
      if (queryParams[prop]) {
        let val = queryParams[prop];

        if (Array.isArray(val)) {
          val = val.join(',');
        }

        params = params.set(prop, '' + val); // params is muttable
      }
    }

    return params;
  }

}
