import { EntitiesState, selectEntities as selectEntitiesState } from '../entities';
import { createSelector } from '@ngrx/store';
import { assetAdapter } from './assets.reducer';

const {
  selectEntities,
  selectAll
} = assetAdapter.getSelectors();

export const selectAssets = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.assets
);

export const selectAllAssets = createSelector(selectAssets, selectAll);
export const selectAssetEntities = createSelector(selectAssets, selectEntities);

export const selectAssetById = (id: string) => createSelector(selectAssetEntities, assetDictionary => assetDictionary[id]);
