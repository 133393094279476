import { ComponentFactoryResolver, ComponentRef, Inject, Injectable, Type, ViewContainerRef } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SliderInput, SliderOutput, SliderButtonConfig, SliderConfig } from 'app/shared/models';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class SideNavService {
  public config: SliderConfig;

  public slider: MatSidenav = null;
  public componentPlaceholder: ViewContainerRef = null;
  public factoryResolver: ComponentFactoryResolver;
  private currentComponent: ComponentRef<unknown>;
  actionButtons = new BehaviorSubject<SliderButtonConfig[]>([]);

  constructor(
    @Inject(ComponentFactoryResolver) factoryResolver) {

    this.factoryResolver = factoryResolver;

    this.initConfig();
  }

  //#region Slider visibility

  /**
   * Open slider.
   */
  public show(): void {
    this.slider.open();
  }

  /**
   * Close slider.
   */
  public hide(): void {
    this.slider.close();

    this.cleanUpAfterClose();
  }

  /**
   * Toggle slider.
   */
  public toggle() {
    const val = this.slider.opened.valueOf();

    if (!val) {
      this.show();
    } else {
      this.hide();
    }

  }

  //#endregion

  /**
   * Init config method.
   */
  private initConfig(): void {
    this.config = {
      disableClose: true,
      autoFocus: false,
      buttons: [],
    } as SliderConfig;
  }

  /**
   * This method will release all resources used by dynamic component, and reint slider's config.
   */
  private cleanUpAfterClose(): void {
    // remove dynamic component (ngOnDestroy)
    this.componentPlaceholder.clear();
    // re-init config
    this.initConfig();
  }

  /**
   * Slider register method, we'll provide slider object here.
   *
   * @param slider
   */
  public registerSlider(slider: MatSidenav): this {
    if (this.slider) {
      // throw new Error('Only one slider register posible!');
    }

    this.slider = slider;

    // on slider close cleanup
    this.slider
      .openedChange
      .subscribe(sliderOpened => !sliderOpened && this.cleanUpAfterClose());

    return this;
  }

  /**
   *
   * @param viewContainerRef
   */
  public setRootViewContainerRef(viewContainerRef): void {
    if (this.componentPlaceholder) {
      // throw new Error('Only one viewContainerRef set posible!');
    }
    this.componentPlaceholder = viewContainerRef;
  }

  /**
   * Method used for adding dynamic contnet to our slider.
   *
   * @param component
   * @param componentInputs
   * @param componentOutputs
   */
  public addComponent(component: Type<any>, componentInputs?: SliderInput, componentOutputs?: SliderOutput): this {

    const factory = this.factoryResolver.resolveComponentFactory(component);
    // clear previous component
    this.componentPlaceholder.clear();

    this.currentComponent = this.componentPlaceholder.createComponent(factory);

    this.bindInputsAndOutputs(componentInputs, componentOutputs);

    return this;
  }

  /**
   * Method used to generate slider config.
   *
   * @param config
   */
  public addConfig(config: SliderConfig): this {

    const tmpConfig = { ...config };
    // emit button change
    this.actionButtons.next(tmpConfig.buttons);

    delete tmpConfig.buttons;
    Object.assign(this.config, tmpConfig);

    return this;
  }

  /**
   * Method that will execute proper button's action and close slider if needed.
   *
   * @param button
   */
  public handleActionButtonClick(button: SliderButtonConfig): void {
    if (typeof button.action === 'function') {
      button.action() && this.hide();
    } else if (typeof button.asyncAction === 'function') {
      button.asyncAction()
        .pipe(first())
        .subscribe(v => v && this.hide());
    } else {
      throw Error(`Button '${button.title}' must has at least one action (action or asyncAction)!`);
    }
  }

  /**
   * Method used to apply inputs and outputs to our component.
   *
   * @param componentInputs
   * @param componentOutputs
   */
  private bindInputsAndOutputs(componentInputs?: SliderInput, componentOutputs?: SliderOutput): void {
    // bind inputs
    for (const prop in componentInputs) {
      if (Object.prototype.hasOwnProperty.call(componentInputs, prop)) {
        this.currentComponent.instance[prop] = componentInputs[prop];
      }
    }

    // bind outputs
    for (const prop in componentOutputs) {
      if (Object.prototype.hasOwnProperty.call(componentOutputs, prop)) {
        this.currentComponent
          .instance[prop]
          .subscribe(componentOutputs[prop]);
      }
    }

  }
}
