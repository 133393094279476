import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe used for functions inside tpl
 *
 * @link https://medium.com/angular-in-depth/tiny-angular-pipe-to-make-any-function-memoizable-f6c8fa917f2f
 */
@Pipe({
  name: 'memoize',
  pure: true //can be omitted as default value
})
export class MemoizePipe implements PipeTransform {

  transform(fn: (...arg: unknown[]) => unknown, ...args: unknown[]): unknown {
    return fn(...args);
  }

}
