import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Zenserp, ZenserpResponse } from '@app-models';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Third party service used for obtaining company images.
 */
@Injectable({
  providedIn: 'root'
})
export class ZenserpService {
  private readonly api: string;
  private readonly token: string;

  constructor(private http: HttpClient) {
    // this.api = environment.zenserp.api;
    // this.token = environment.zenserp.token;
  }

  /**
   * Provide images for given query.
   *
   * @param query     Query to search for.
   * @param pageSize  Desired length of the response.
   * @param sufix     Sufix to append to the query.
   *
   * @returns Array of matched images.
   */
  public getImages(query: string, pageSize = 5, sufix = ' logo'): Observable<Zenserp[]> {
    const searchType = 'isch'; // images

    if (query.trim().length === 0) {
      return of([]);
    }

    return this.http
      .get(`${this.api}search?apikey=${this.token}&q=${query + sufix}&tbm=${searchType}&num=${pageSize}`)
      .pipe(map(r => this.mapResponse(r as ZenserpResponse, pageSize)));
  }

  /**
   * Extract our value from the Zenserp API respose.
   *
   * @param response  Response from the  Zenserp API.
   * @param pageSize  Desired lenght of response.
   *
   * @returns Array of images found in our response.
   */
  private mapResponse(response: ZenserpResponse, pageSize): Zenserp[] {
    // ATM zenserp doesn't limit reuturned value - it is allways 100
    if (response.image_results) {
      return response.image_results.splice(0, pageSize);
    }

    return [];
  }
}
