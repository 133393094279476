import { createAction, props } from '@ngrx/store';
import { Country } from './country.model';

export const countryUpserMany = createAction(
  '[Country] Upsert many',
  props<{ countries: Country[] }>()
);

export const countryGetAll = createAction(
  '[Country] Get All Countries'
);

export const countrySelectedCountryIdByAlpha2 = createAction(
  '[Country] Selected by id',
  props<{ alpha2: string }>()
);
