import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Larasponse } from 'app/shared/models';
import { SnackBarService } from '../services';

/**
 * Interceptor responsible for handling all successfull http responses.
 */
@Injectable()
export class LaravelMessagesInterceptor implements HttpInterceptor {

  constructor(public snackBarService: SnackBarService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next
      .handle(req)
      .pipe(
        tap((evt: HttpResponse<Larasponse<any>>) => {
          if (evt.body) {
            const response = evt.body as Larasponse<any>;

            if (response.success && response.messages !== null) {
              const messages = (response.messages as Array<string>).join('<br />');
              this.snackBarService.showSuccessMessage(messages);
            }
          }
        })
      );
  }

}
