import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CoreApiService {

  public static readonly WS_ADDRESS = environment.webServiceAddress;

  constructor(protected http: HttpClient) { }

  /**
   * Generic Get method, will return single element of type T.
   *
   * @param slug    Slug for requested resource.
   * @param params  params for requested resource.
   */
  protected get<T>(slug: string, params = null): Observable<T> {
    return this.http.get<T>(`${environment.webServiceAddress}${slug}`, { params });
  }

  /**
   * Post method.
   */
  protected post<T>(slug: string, data: unknown = null, options?): Observable<T> {
    return this.http.post<T>(`${environment.webServiceAddress}${slug}`, data);
  }

  /**
   * Generic Put method.
   *
   * @param slug Slug for requested resource.
   * @param body Body for requested resource
   */
  protected put<T>(slug: string, body: unknown = null, options?): Observable<any> {
    return this.http.put<T>(`${environment.webServiceAddress}${slug}`, body, options);
  }

  /**
   * Generic Patch method.
   *
   * @param slug Slug for requested resource.
   * @param body Body for requested resource
   */
  protected patch<T>(slug: string, body: unknown = null): Observable<T> {
    return this.http.patch<T>(`${environment.webServiceAddress}${slug}`, body);
  }

  /**
   * Generic Delete method.
   *
   * @param slug Slug for requested resource.
   */
  protected delete<T>(slug: string): Observable<any> {
    return this.http.delete(`${environment.webServiceAddress}${slug}`);
  }
}
