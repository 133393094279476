import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';

import { SharedModule } from './shared/modules/shared.module';
import { CoreModule } from './core/core.module';

import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import { MatIconRegistry } from '@angular/material/icon';
registerLocaleData(localeEn);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,

    // core & shared
    CoreModule,
    SharedModule,

    // routing
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(public matIconRegistry: MatIconRegistry) {
    this.initFontAwesome();
  }

  private initFontAwesome(): this {
    this.matIconRegistry.registerFontClassAlias('fontawesome', 'fa');
    return this;
  }
}
