import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PopupButtonType, PopupDialogType, VatAutocompleteStatus } from '@app-enums';
import { OrderByDirection, Zenserp } from '@app-models';
import { PopupDialogComponent } from '@app-shared-components/popup-dialog/popup-dialog.component';
import { PopupDialogButtonDefinition } from 'app/shared/models/popup/popup-dialog-button-definition.model';
import { PopupDialogConfig } from 'app/shared/models/popup/popup-dialog-config.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CompanyService } from 'app/core/services/companies/company.service';
import { Store } from '@ngrx/store';
import { CompanyFirestore } from '@app-store-entities';
import { CompanyFormService } from 'app/core/services/companies/company-form.service';

@Injectable({ providedIn: 'root' })
export class CompaniesFacade {
  // company form, used for create and update
  public get form(): FormGroup {
    // return this.formService.form;
    return new FormGroup({});
  }

  constructor(
    private store: Store<any>,
    private companyService: CompanyService,
    public formService: CompanyFormService,
    private dialogService: MatDialog,
  ) { }

  //#region Firestore section

  /**
   * Load companies.
   *
   * @returns Observable containing array of companies.
   */
  public getCompanies(
    sortColumn = 'name',
    sortDirection: OrderByDirection = 'asc',
    limit = 5,
    pageIndex: number,
    query: string = ''
  ): Observable<CompanyFirestore[]> {
    return this.companyService.getAllCompanies(sortColumn, sortDirection, limit, query);
  }

  //#endregion

  //#region CRUD actions

  /**
   * Store company form to the firebase.
   */
  public async create() {
    // const company = this.formService.formToObject();

    // this.formService.cleanup();
  }

  /**
   * Update current company form to the firestore.
   */
  public async update() {
    // const company = this.formService.formToObject();

    // this.formService.cleanup();
  }

  //#endregion

  //#region Utility methods

  public showCanLeaveDialog(): Promise<boolean> {

    const dialogConfig = {
      title: 'Warning',
      content: 'Do you really want to Cancel adding a new Customer. All changes will we discarded',
      type: PopupDialogType.warning,
      buttonsDef: [
        {
          icon: 'fas fa-times',
          title: 'Cancel',
          matDialogClose: false,
          type: PopupButtonType.gray,
        } as PopupDialogButtonDefinition,
        {
          icon: 'fas fa-trash',
          title: 'delete',
          matDialogClose: true,
          type: PopupButtonType.primary,
        } as PopupDialogButtonDefinition,
      ]
    } as PopupDialogConfig;

    const conf = { data: dialogConfig } as MatDialogConfig<PopupDialogConfig>;

    return this.dialogService
      .open(PopupDialogComponent, conf)
      .afterClosed()
      .toPromise();
  }

  //#endregion
}
