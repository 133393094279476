import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Country } from '@app-store-entities';
import { CountryState } from '../../model/country-state/country-state.model';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  private readonly api = `environment.countryState.api`;
  constructor(protected http: HttpClient) { }

  getCountries(): Observable<Country[]> {
    return this.http.get<Country[]>(`${this.api}countries/region/Europe`);
  }

  getCountry(alpha2: string): Observable<Country> {
    return this.http.get<Country>(`${this.api}countries/${alpha2}`);
  }

  getCountryStates(alpha2: string): Observable<CountryState[]> {
    return this.http.get<CountryState[]>(`${this.api}countries/${alpha2}/states`)
      .pipe(catchError(err => []));
  }
}
