import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderByDirection } from '@app-models';
import { Observable } from 'rxjs';
import { CompanyFirestore } from '@app-store-entities';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private fakeUrl: string;
  private companiesEndpoint = '/assets/fakeData/';

  constructor(protected http: HttpClient) {
    this.fakeUrl = `${window.location.protocol}//${window.location.host}` + this.companiesEndpoint;
  }

  /**
   * Get all company from API
   *
   * @param sortColumn name of the column sort
   * @param sortDirection sort asc or desc
   * @param limit listed rows on signle page on pagination
   * @param query search filter
   * @returns Observable containing requested companies
   */
  public getAllCompanies(
    sortColumn?: string,
    sortDirection: OrderByDirection = 'asc',
    limit?: number,
    query?: string): Observable<CompanyFirestore[]> {
    return this.http.get<CompanyFirestore[]>(this.fakeUrl + 'companies.json');
  }

  /**
   *
   * @param companyID id of company
   * @returns Observable containing requested company
   */
  public getCompanyById(companyID?: string): Observable<CompanyFirestore> {
    return this.http.get<CompanyFirestore>(this.fakeUrl + 'company.json');
  }

}
