import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MediaMatcher } from '@angular/cdk/layout';

//#region Services

import {
  AuthHttpService, CountryEffects,
} from './services';

const projectServiceProviders = [
  AuthHttpService,
];

//#endregion

//#region Guards

import { AuthGuard, LoginGuard } from './guards';
const projectGuardProviders = [
  AuthGuard,
  LoginGuard,
];

//#endregion

//#region Interceptors

import {
  JwtInterceptor,
  GlobalErrorHandler,
  HttpErrorInterceptor,
  LaravelMessagesInterceptor,
} from './interceptors';
//#endregion

import { environment } from 'environments/environment';
import { CompaniesFacade } from 'app/modules/companies/companies.facade';
import { StoreModule } from '@ngrx/store';
import { FEATURE_NAME, reducers } from './model/entities';
import { EffectsModule } from '@ngrx/effects';
import { CompanyEffects } from './services/companies/company.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AssetEffects } from './services/assets/asset.effects';
import { CountryStateEffects } from './services/country/country-state.effects';
import { TeltonikaEffects } from './services/teltonikas/teltonika.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    // store
    StoreModule.forRoot(reducers),
    StoreModule.forFeature(FEATURE_NAME, reducers),
    EffectsModule.forRoot([CompanyEffects, CountryEffects, AssetEffects, TeltonikaEffects]),
    StoreDevtoolsModule.instrument({ maxAge: 15 }),
    EffectsModule.forFeature([CountryStateEffects]),
  ],
  providers: [
    MediaMatcher,
    ...projectServiceProviders,
    ...projectGuardProviders,
    CompaniesFacade,
    // Global error handler
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: LaravelMessagesInterceptor, multi: true },

    // Storage
    { provide: 'LOCALSTORAGE', useValue: window.localStorage },
    { provide: 'SESSIONSTORAGE', useValue: window.sessionStorage },
    // Locale
    // {
    //   provide: LOCALE_ID,
    //   useValue: 'en-gb'
    // },
  ],
  exports: [],
})
/**
 * This module is for classes used by app.module.
 * Resources which are always loaded such as route guards,
 * HTTP interceptors, and application level services,
 * such as the ThemeService and logging belong in this directory.
 * CoreModule should be loaded only once, which is taken care of in contstructor.
 */
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(`CoreModule has already been loaded. Import Core modules in the AppModule only.`);
    }
  }
}
