import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderByDirection, AssetTableRow } from '@app-models';

import { Observable } from 'rxjs';
import { Asset } from '../../model/assets/asset.model';

@Injectable({
  providedIn: 'root'
})
export class AssetService {
  private fakeUrl: string;
  private companiesEndpoint = '/assets/fakeData/';

  constructor(protected http: HttpClient) {
    this.fakeUrl = `${window.location.protocol}//${window.location.host}` + this.companiesEndpoint;
  }

  /**
   * Gett all asssets which belongs to one company
   *
   * @param sortColumn name of the column sort
   * @param sortDirection sort asc or desc
   * @param limit listed rows on signle page on pagination
   * @param pageIndex page index of pagination
   * @param query search filter
   * @param companyId: company ID string
   * @returns  Observable containing requested assets.
   */
  public getAllAssetsByCompanyId(
    companyId: string,
    sortColumn?: string,
    sortDirection: OrderByDirection = 'asc',
    limit?: number, query?: string
    ): Observable<Asset[]> {

    return this.http.get<Asset[]>(this.fakeUrl + 'assets.json');
  }

  /**
   * Gett all asssets which belongs to one company
   *
   * @param companyId id of company
   * @param sortColumn name of the column sort
   * @param sortDirection sort asc or desc
   * @param limit listed rows on signle page on pagination
   * @param pageIndex page index of pagination
   * @param query search filter
   * @param companyID: company ID string
   * @returns  Observable containing requested assets.
   */
  public getAssetById(
    sortColumn: string,
    sortDirection: OrderByDirection = 'asc',
    limit: number,
    query: string,
    assetId: string): Observable<Asset> {

    return this.http.get<Asset>(this.fakeUrl + 'asset.json');
  }
}
