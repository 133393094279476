import { Component } from '@angular/core';
import { SideNavService } from 'app/core/services/utility/side-nav.service';

@Component({
  selector: 'dad-application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.scss']
})
export class ApplicationLayoutComponent {

  constructor(public sideNavService: SideNavService) { }

}
