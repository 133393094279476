import { createAction, props } from '@ngrx/store';
import { Teltonika } from './teltonika.model';
import { OrderByDirection } from '@app-models';

export const teltonikaUpsertMany = createAction(
  '[Teltonika] Upsert Many',
  props<{ teltonikas: Teltonika[] }>()
);

export const teltonikaUpsertOne = createAction(
  '[Teltonika] Upsert One',
  props<{ teltonika: Teltonika }>()
);

export const teltonikaDeleteMany = createAction(
  '[Teltonika] Delete Many',
  props<{ ids: string[] }>()
);

export const teltonikaDeleteOne = createAction(
  '[Teltonika] Delete One',
  props<{ id: string }>()
);

//sortColumn?: string; sortDirection?: OrderByDirection; limit?: number; query?: string
export const teltonikaGetAll = createAction(
  '[Teltonika] Get All Teltonikas',
  props<{ sortColumn?: string; sortDirection?: OrderByDirection; limit?: number; page: number; query?: string }>()
);
