<mat-card [ngClass]="backgroundClass" class="card-border">
  <mat-card-header class="tw-uppercase" style="margin-bottom: 24px;">
    <ng-content select="[card-title]"></ng-content>
  </mat-card-header>

  <mat-card-content>
    <ng-content select="[card-content]"></ng-content>
  </mat-card-content>

  <mat-card-actions>
    <ng-content select="[card-actions]"></ng-content>
  </mat-card-actions>
</mat-card>
