import { Actions, createEffect, ofType } from '@ngrx/effects';
import { companyGetAll, companyUpsertMany } from '@app-store-entities';
import { map, mergeMap } from 'rxjs/operators';
import { CompanyService } from './company.service';
import { Injectable } from '@angular/core';

@Injectable()
export class CompanyEffects {

  loadCompanies$ = createEffect(() => this.actions$.pipe(
    ofType(companyGetAll),
    mergeMap(({ sortColumn, sortDirection, limit, query }) =>
      this.companyService.getAllCompanies(sortColumn, sortDirection, limit, query).pipe(
        map(companies => companyUpsertMany({ companies }))
      )
    )
  ));

  constructor(private actions$: Actions, private companyService: CompanyService) {
  }
}
