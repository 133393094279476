import { EntityState } from '@ngrx/entity';
import { CompanyFirestore } from '../company';

export interface Teltonika {
  name: string;
  model: string;
  firmware: string;
  imei: string;
  company_name: string;

  asset?: string;
  company?: CompanyFirestore;
  updated_at: string;
}

export interface TeltonikaState extends EntityState<Teltonika> { }
