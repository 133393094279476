<input hidden #inputFileUpload type="file" accept="image/png, image/jpeg" (change)="onUploadedChange($event)" />
<div fxLayout="row" fxLayoutAlign="end center">
  <div *ngIf="suggestedPhotos?.length > 0">
    <button matTooltip="Navigate backwards through suggested photos" mat-icon-button (click)="onPrevSuggestedPhoto()">
      <i class="fas fa-arrow-left"></i>
    </button>
  </div>
  <div dadRegularSkeletonLoader *ngIf="(uploadedPhoto$ | async) let suggestedPhotoObject" (click)="onUploadPhoto()"
    class="camera-button dark-gray-border border-full-circle">

    <img *ngIf="suggestedPhotoObject.isFontAwesome === false; else fontAwesome"
      [ngClass]="{'can-upload-photo': suggestedPhotoObject.isDefault}" [src]="suggestedPhotoObject.src"
      [matTooltip]="suggestedPhotoObject.tooltip" class="border-full-circle" />
    <ng-template #fontAwesome>
      <i matTooltip="Upload png or jpg image with max size 3mb."
        [ngClass]="{'can-upload-photo': suggestedPhotoObject.isDefault}"
        class="font-size-48 camera-button-default fas fa-camera dark-gray-color"></i>
    </ng-template>

  </div>
  <div *ngIf="suggestedPhotos?.length > 0">
    <button matTooltip="Navigate forward through suggested photos" mat-icon-button (click)="onNextSuggestedPhoto()">
      <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</div>
