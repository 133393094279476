import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'pluck' })
export class PluckPipe implements PipeTransform {
  transform(input: unknown[], key: string): unknown {
    if (!input) {
      return [];
    }
    return input.map(value => value[key]);
  }
}
