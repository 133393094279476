import { AfterViewInit, ChangeDetectionStrategy, Component, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RouteName } from '@app-enums';
import { BreadcrumbEntityNameSelector } from '@app-models';
import { ApplicationNavigationService } from '@app-services';
import { PageUpdateCompanyStore } from 'app/core/store/companies/component/page-update-company.store';
import { Observable, of, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'dad-application-breadcrumb',
  templateUrl: './application-breadcrumb.component.html',
  styleUrls: ['./application-breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationBreadcrumbComponent implements AfterViewInit, OnDestroy {
  public breadcrumb$ = new Subject<string[]>();

  private unsubscribe$ = new Subject<boolean>();

  public breadcrumbEntityMap = {
    [RouteName.moduleCompanies]: PageUpdateCompanyStore,
  };
  constructor(
    private router: Router,
    private appNavService: ApplicationNavigationService,
    private injector: Injector,
  ) { }

  //#region Life-cycle hooks

  ngAfterViewInit(): void {
    this.initBreadcrumb();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //#endregion

  /**
   * Navigate back UI method.
   */
  public async onNavigateBack() {
    this.router.navigateByUrl(this.appNavService.getBackUrl());
  }

  /**
   * Initialize breadcrumb by providing values to breadcrumb$ subject.
   * Currently, each breadcrumb has MODULE_NAME / PAGE_NAME.
   */
  private initBreadcrumb(): void {
    // all non empty, because split with '/' will return some empty strings
    const routeParams = this.router.url.split('/').filter(s => !!s);

    // handle route params
    const len = routeParams.length;

    const bcmbBase = [this.convertToText(`bcmb_${routeParams[0]}`)];

    // has long enough url
    (len > 1) && bcmbBase.push('/');

    if (len === 2) {
      // we're on create page
      this.breadcrumb$.next([...bcmbBase, this.convertToText(`bcmb_${routeParams[0]}_${routeParams[1]}`)]);
    } else if (len >= 3) {
      // we're on update entity page
      const entity$ = this.getEntityName(routeParams[0] as RouteName, routeParams[2]);
      // update page
      entity$
        .pipe(takeUntil(this.unsubscribe$)) // event will be triggered for each change
        .subscribe(e => this.breadcrumb$.next([...bcmbBase, e]));
    }
  }

  private getEntityName(module: RouteName, id: string | number): Observable<string> {
    let entityName = '-';

    try {
      return this.injector.get<BreadcrumbEntityNameSelector>(this.breadcrumbEntityMap[module]).getEntityName$;

    } catch (e) {
      new Error(`No entity map for ${module}`);
    }

    return of(entityName);
  }

  /**
   * Convert to real text - dummy method for now.
   *
   * @param bcmbBase Base bcmb.
   *
   * @returns Actual breadcrumb label.
   */
  private convertToText(bcmbBase: string): string {
    switch (bcmbBase) {
      case 'bcmb_companies':
        return 'Company';
      case 'bcmb_companies_create':
        return 'Adding new company';
      case 'bcmb_telematic-systems':
        return 'Telematic system';
      case 'bcmb_telematic-systems_create':
        return 'Adding new telematic system';
      case 'bcmb_teltonika':
        return 'Teltonika';
    }
  }

}
