import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { CanLeaveComponent } from '@app-models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CanLeaveFormGuard implements CanDeactivate<CanLeaveComponent> {
  canDeactivate(
    component: CanLeaveComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    return component.canLeaveComponent();
  }
}
