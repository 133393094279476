import { Injectable } from '@angular/core';
import {
  NavigationCancel,
  NavigationError,
  NavigationStart,
  RouterEvent,
  Router,
} from '@angular/router';
import { RouteName } from '@app-enums';

@Injectable({
  providedIn: 'root'
})
export class ApplicationNavigationService {
  // application nav history queue
  public history: string[] = [];

  public get url(): string {
    return this.router.url;
  }

  constructor(private router: Router) {
    // track router changes
    this.router
      .events
      .subscribe(this.handleRouterEvent);
  }

  /**
   * Subscriber to route events change.
   *
   * @param e RouterEvent.
   */
  private handleRouterEvent = (e: RouterEvent): void => {
    if (e instanceof NavigationStart) {
      this.history.push(e.url);
    } else if (e instanceof NavigationCancel || e instanceof NavigationError) {
      if (this.history[this.history.length - 1] === e.url) {
        this.history.length = this.history.length - 1;
      }
    }
  };

  /**
   * Provide back url for current page.
   * This method provides two ways for obtainig back page, one where
   * navigation page will be determined according to the history of navigation
   * and other where we'll return to te module root.
   *
   * @param inHistory Should we use navigation history.
   *
   * @returns Generated back url.
   */
  public getBackUrl(inHistory = false): string {
    const historyLen = this.history.length;

    // we can and shall provide historical back url if needed, otherwise we'll give module's route path
    if (historyLen >= 2 && inHistory) {
      return this.history[historyLen - 2];
    }

    const modules = [RouteName.moduleCompanies, RouteName.moduleTelematicSystems];

    return modules.find(m => this.router.url.startsWith(`/${m}`));
  }
}
