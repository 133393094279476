import { createEntityAdapter, Dictionary, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { countrySelectedCountryIdByAlpha2, countryUpserMany } from './country.actions';
import { Country, CountryState } from './country.model';

export const countryAdapter: EntityAdapter<Country> = createEntityAdapter<Country>();

export const countryInitialState = countryAdapter.getInitialState({
  selectedCountryId: null,
});

const getCountryIdUsingAlpha2 = (entities: Dictionary<Country>, alpha2: string): number => {
  let id = null;
  if (alpha2 === null) { return id; }
  for (const [key, val] of Object.entries(entities)) {
    if (val.alpha2 === alpha2) {
      id = val.id;
      break;
    }
  }

  return id;
};

const reducer = createReducer(
  countryInitialState,
  on(countryUpserMany, (state: CountryState, { countries }) =>
    countryAdapter.upsertMany(countries, state)
  ),
  on(countrySelectedCountryIdByAlpha2,
    (state: CountryState, { alpha2 }) => ({
      ...state, selectedCountryId: getCountryIdUsingAlpha2(state.entities, alpha2)
    })
  ),
);

export const countryReducer = (state: CountryState = countryInitialState, action: Action): CountryState => reducer(state, action);
