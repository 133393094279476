import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

/**
 * Countries list load from internal JSON file.
 */
@Injectable({ providedIn: 'root' })
export class JsonCountryHttpService {

  constructor(
    private httpClient: HttpClient,
  ) { }

  /**
   * Load all countries on requested lang. Currently supported are
   * en and de.
   *
   * @param lang Requested language, defaults to 'en'.
   *
   * @returns Array of countries.
   */
  public loadAllCountries(lang: 'en' | 'de' = 'en'): Observable<string[]> {
    return this.httpClient.get(`/assets/countrylist/${lang}.json`)
      .pipe(map((response: { [iso: string]: string }) => {
        const arr = [] as string[];
        for (let isoKey in response) {
          arr.push(response[isoKey]);
        }

        return arr;
      }));
  }
}
