import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { RouteName } from 'app/shared/enums';
import { AuthHttpService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(
    private authService: AuthHttpService,
    private router: Router,
  ) { }

  canActivate() {
    // if (this.authService.loggedIn) {
    //   this.router.navigateByUrl(RouteName.moduleCompanies);
    //   return false;
    // } else {
    //   return true;
    // }
    return true;
  }
}
