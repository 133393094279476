import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateFormat, Regex, } from '@app-enums';
import { Company } from '@app-models';
import { BehaviorSubject, first, Subject, takeUntil } from 'rxjs';
import { JsonCountryHttpService } from '../utility/json-country-http.service';

@Injectable({ providedIn: 'root' })
export class CompanyFormService {

  //#region Class properties

  /** All available countries, loaded only once, in constructor */
  private countries: string[] = [];
  /** BS with filtered countries, used in components. */
  public filteredCountries$ = new BehaviorSubject<string[]>([]);

  /**
   * Just memory cleaner for country change. Theoretically we'll subscribe to form that will be
   * deleted on each page change, hence, there is no need to worry about memory leaks, but just in case.
   */
  private unsubscribeFromCountryChange$ = new Subject<boolean>();

  // Instance of company form.
  private myForm: FormGroup;

  // Public getter of myForm instance.
  public get form(): FormGroup {
    return this.myForm;
  }

  //#endregion

  constructor(
    private datePipe: DatePipe,
    private jsonCountryHttpService: JsonCountryHttpService,
  ) {
    this.loadAllCountries();
  }

  /**
   * Form initialization method. Will make new instance of FormGroup with all
   * default values and dependencies, as well as bind to the country change value,
   * later, filtered value can be found in filteredCountries$ property.
   *
   * @returns FormGroup instance.
   */
  private initForm(): FormGroup {
    const emptyValueAndDisabled = { value: '', disabled: true };

    this.filteredCountries$.next(this.countries);

    const form = new FormGroup({
      // card details
      id: new FormControl(emptyValueAndDisabled),
      inactive: new FormControl(emptyValueAndDisabled),
      name: new FormControl('', [Validators.required]),
      referralCode: new FormControl(emptyValueAndDisabled, [Validators.required]),
      ustID: new FormControl(''),
      registeredAt: new FormControl(emptyValueAndDisabled),
      registeredAtDatePiped: new FormControl(emptyValueAndDisabled),
      // card address
      address: new FormGroup({
        city: new FormControl(''),
        country: new FormControl(''),
        houseNumber: new FormControl(''),
        postalCode: new FormControl(''),
        state: new FormControl(''),
        street: new FormControl(''),
      }),
      // card contact person
      contactPerson: new FormGroup({
        email: new FormControl(emptyValueAndDisabled, [Validators.pattern(Regex.validEmail)]),
        name: new FormControl(emptyValueAndDisabled)
      }),
    });

    this.initCountryValueChangeSubscription(form);

    return form;
  }

  /**
   * Method for generating instance of FormGroup that will be used in UpdateCompanyComponent.
   *
   * @param company Instance of Company, from which values will be taken from.
   *
   * @returns Instance of FormGroup, with all values from passed company.
   */
  public generateUpdateCompanyForm(company: Company): FormGroup {
    this.myForm = this.initForm();
    // prepare UI only field for date
    company.registeredAtDatePiped = this.datePipe.transform(company.registeredAt, DateFormat.fullDateTime);
    this.myForm.patchValue(company);

    if (this.myForm.get('inactive').value !== '') {
      this.myForm.get('inactive').enable();
    }

    return this.myForm;
  }

  //#region Utility methods

  /**
   * Destroy current value of myForm, so on next open it will be null.
   */
  public cleanup(): void {
    this.myForm = null;
  }

  /**
   * Load all countries.
   *
   * @returns Self, for chaining.
   */
  private loadAllCountries(): this {

    this.jsonCountryHttpService
      .loadAllCountries()
      .pipe(first())
      .subscribe(countries => {
        this.countries = countries;
        this.filteredCountries$.next(countries);
      });

    return this;
  }

  /**
   * Method used for subscribing to country value changes. On each change,
   * property filteredCountries$ will be updated.
   *
   * @param form
   */
  private initCountryValueChangeSubscription(form: FormGroup): void {
    // remove loose ends from the previous form (takeUntil)
    // technical, form wouldn't exist any more, so subscribe
    // wouldn't get executed, but...
    this.unsubscribeFromCountryChange$.next(true);
    this.unsubscribeFromCountryChange$.complete();

    form.get('address.country')
      .valueChanges
      .pipe(
        takeUntil(this.unsubscribeFromCountryChange$)
      ).subscribe(this.handleCountryValueChange);
  }

  /**
   * Perform filtering over all countries.
   *
   * @param query Query for search.
   */
  private handleCountryValueChange = (query: string): void => {
    // prepare filter function
    const filterFunction =
      (country: string) => country.toLocaleLowerCase().includes(query.toLocaleLowerCase());
    // apply it
    const filteredCountries = this.countries.filter(filterFunction);
    // update UI
    this.filteredCountries$.next(filteredCountries);
  };

  //#endregion

}
//#region COMPLETE VERSION
/**
 * This service is used as full service for lots of functionalities that were part of initial request.
 *
 * Some of theme were:
 *  - automatically data fullfimnent according to VAT
 *  - automatically image selection
 *  - country/state selection
 *  - etc...
 */
// export class CompanyFormService {
//   /** Triggering threashold for VAT autocomplete */
//   public static readonly VAT_CHAR_TRESHOLD = 8;

//   /** Time in miliseconds need for single autocomplete message to be visible */
//   public static readonly VAT_AUTOCOMPLETE_DELAY = 1500;

//   /**Information about VAT autocomplete status */
//   public vatAutocompleteStatusChange$ = new Subject<VatAutocompleteStatus>();

//   /** Our form instance */
//   public form: FormGroup;
//   /** Subscriptions for form value changes */
//   private initFieldsSubs = new Subscription();

//   /** VAT autocomplete suggested photos */
//   public suggestedPhotos$ = new BehaviorSubject<Zenserp[]>([]);

//   constructor(
//     private store: Store<any>,
//     private vatLayerService: VatlayerService,
//     private zipcodebaseService: ZipcodebaseService,
//     private zenserpService: ZenserpService,
//   ) { }

//   /**
//    * Initialize form with all empty fields.
//    *
//    * @param loadDropdowns Should we load dropdown menues.
//    *
//    * @returns Self.
//    */
//   public initForm(loadDropdowns = true): this {
//     this.form = new FormGroup({
//       // companyId
//       companyID: new FormControl(null),

//       photo: new FormControl(),

//       // tab company info
//       name: new FormControl(),
//       ustID: new FormControl(null, [Validators.required]),

//       // tab address
//       location: new FormGroup({
//         country: new FormControl(),
//         bcc_code: new FormControl(),
//         code: new FormControl(null, [Validators.maxLength(2)]),
//         zip: new FormControl(),
//         city: new FormControl(),
//         street: new FormControl(),
//         state: new FormControl({ value: null, disabled: true }),
//         longitude: new FormControl(),
//         latitude: new FormControl(),
//       }),
//       // tab drip admin account
//       dripAdmin: new FormGroup({
//         email: new FormControl('', [Validators.email]),
//         password: new FormControl('', [Validators.required]),
//         confirmPassword: new FormControl('', [Validators.required]),

//         userName: new FormControl(),
//         userPhone: new FormControl(),
//         userOrt: new FormControl(),
//         userEmail: new FormControl('', [Validators.email]),
//         userRole: new FormControl({ value: 'ADMIN', disabled: true }),
//       }),
//     });

//     // load dropdown menues
//     loadDropdowns && (this.loadCountriesDropdown());

//     this.initFormfields();

//     return this;
//   }

//   /**
//    *
//    */
//   private initFormfields(): void {
//     // usnubsribe from previous value change events
//     this.initFieldsSubs.unsubscribe();
//     this.initFieldsSubs = new Subscription();

//     // call vat api
//     const subsVat = this.form.get('ustID')
//       .valueChanges
//       .pipe(
//         filter((v: string) => v && v.length >= CompanyFormService.VAT_CHAR_TRESHOLD),
//       ).subscribe(this.onVatValueChanges);
//     this.initFieldsSubs.add(subsVat);

//     // sync code and bcc_code, so we'll have
//     const locFG = this.form.get('location');
//     const codeFC = locFG.get('code');
//     const bccCodeFC = locFG.get('bcc_code');

//     const subsFCValChange = codeFC.valueChanges
//       .pipe(
//         filter(alpha2 => alpha2.length === 2))
//       .subscribe(async alpha2 => {
//         // form stuff
//         bccCodeFC.setValue(alpha2, { emitEvent: false, onlySelf: true });
//         locFG.get('state').enable();
//         // store stuff
//         this.store.dispatch(countrySelectedCountryIdByAlpha2({ alpha2 }));
//         this.store.dispatch(loadCountryStatesByAlpha2({ alpha2 }));
//       });
//     this.initFieldsSubs.add(subsFCValChange);

//     const subsBccCodeValChange = bccCodeFC.valueChanges
//       .pipe(
//         filter(alpha2 => alpha2.length === 2))
//       .subscribe(async alpha2 => {
//         // form stuff
//         codeFC.setValue(alpha2, { emitEvent: false, onlySelf: true });
//         locFG.get('state').enable();
//         // store stuff
//         this.store.dispatch(countrySelectedCountryIdByAlpha2({ alpha2 }));
//         this.store.dispatch(loadCountryStatesByAlpha2({ alpha2 }));
//       });

//     this.initFieldsSubs.add(subsBccCodeValChange);
//   }

//   private onVatValueChanges = async (vatQuery: string) => {
//     // msg: init - info
//     this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.initialized);
//     const companyByVAT = await this.vatLayerService.get(vatQuery).toPromise();
//     // clear previous fields?
//     this.emptyVatAutofillFields();

//     if (!companyByVAT) {
//       this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.invalidVat);
//       return;
//     }
//     this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.fillingFormData);
//     this.form.get('name').setValue(companyByVAT.company_name);

//     const locationFG = this.form.get('location');

//     locationFG.get('code').setValue(companyByVAT.country_code);
//     try {
//       const { zip, city, street, state, longitude, latitude }
//         = await new VatAutocompleteFactory().processResponse(companyByVAT, this.zipcodebaseService);

//       locationFG.get('state').setValue(state);
//       locationFG.get('zip').setValue(zip);
//       locationFG.get('city').setValue(city);
//       locationFG.get('street').setValue(street);

//       locationFG.get('longitude').setValue(longitude);
//       locationFG.get('latitude').setValue(latitude);
//     } catch (e) {
//       // todo @boris: handle this scenario
//       this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.unableToPerformVATAutocomplete);
//       // console.error(e);
//     }

//     this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.loadingImages);

//     // update suggested photos
//     const suggestedPhotos = await this.zenserpService.getImages(companyByVAT.company_name).toPromise();
//     this.suggestedPhotos$.next(suggestedPhotos);

//     this.vatAutocompleteStatusChange$.next(VatAutocompleteStatus.done);
//   };

//   /**
//    * Clear fields that are updated during vat autocomplete process.
//    */
//   private emptyVatAutofillFields(): void {
//     this.suggestedPhotos$.next([]);

//     this.form.get('name').setValue('');

//     const locationFG = this.form.get('location');
//     const locFGfields = ['street', 'zip', 'city', 'state', 'longitude', 'latitude', 'code', 'bcc_code'];
//     for (const field of locFGfields) {
//       locationFG.get(field).setValue('');
//     }
//   }

//   /**
//    * Load all countries.
//    */
//   public loadCountriesDropdown(): void {
//     this.store.dispatch(countryGetAll());
//   }

//   /**
//    * This method will convert our form to the company object, ready for the server.
//    *
//    * @returns Company object.
//    */
//   public formToObject(): CompanyFirestore {
//     const company = this.form.value as CompanyFirestore;
//     delete company.address.bcc_code;

//     return company;
//   }

//   /**
//    * After all work with form is done (eg leaving from create/update component)
//    * execute this method to avoid leaked subscritions.
//    */
//   public cleanup(): void {
//     this.initFieldsSubs.unsubscribe();
//   }
// }
//#endregion
