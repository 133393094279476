import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import {
  loadCountryStatesByAlpha2,
  upsertCountryStates,
  upsertCountryStateSelectedCountryAlpha2s
} from 'app/core/model/country-state/country-state.actions';
import { selectAllSelectedCountryIds } from 'app/core/model/country-state/country-state.selectors';
import { EMPTY } from 'rxjs';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CountryService } from './country.service';

@Injectable()
export class CountryStateEffects {
  loadCountryStates$ = createEffect(() => this.actions$.pipe(
    ofType(loadCountryStatesByAlpha2),
    withLatestFrom(this.store.select(selectAllSelectedCountryIds)),
    mergeMap((project) => {
      const alpha2 = project[0].alpha2;
      if (!alpha2 || !project[1].includes(alpha2)) {
        // update store with cuntry alpha2
        this.store.dispatch(upsertCountryStateSelectedCountryAlpha2s({ alpha2 }));
        return this.service
          .getCountryStates(alpha2)
          .pipe(map(countryStates => upsertCountryStates({ countryStates })));
      }

      return EMPTY;
    })
  ));

  constructor(
    private actions$: Actions,
    private service: CountryService,
    private store: Store<any>,
  ) { }

}
