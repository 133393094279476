import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupButtonType, PopupDialogType } from '@app-enums';
import { PopupDialogConfig } from 'app/shared/models/popup/popup-dialog-config.model';

@Component({
  selector: 'dad-popup-dialog',
  templateUrl: './popup-dialog.component.html',
  styleUrls: ['./popup-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupDialogComponent {
  public dialogType = PopupDialogType;
  public buttonType = PopupButtonType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupDialogConfig
  ) { }

  public buttonTypeToClassName(type: PopupButtonType): string {
    let className = '';
    switch (type) {
      case PopupButtonType.gray:
        className = 'gray-button';
        break;
      case PopupButtonType.green:
        className = 'green-button';
        break;
      default:
        className = 'primary-button';
    }

    return className;
  }

  public popupTypeToClassName(type: PopupDialogType): string {
    let className = '';

    switch (type) {
      case PopupDialogType.error:
      case PopupDialogType.warning:
        className = 'fal fa-exclamation-triangle primary-color';
        break;
      default:
        className = 'far fa-check-circle green-color';
    }

    return className;
  }
}
