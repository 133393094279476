<mat-sidenav-container class="example-container">
  <!-- sidenav -->
  <mat-sidenav #left id="app-main-sidenav" mode="side" opened class="light-gray-background">
    <dad-application-side-menu></dad-application-side-menu>
  </mat-sidenav>

  <!-- application core -->
  <mat-sidenav-content id="app-main-sidenav-content">
    <!-- header -->
    <dad-application-header></dad-application-header>

    <!-- body -->
    <div id="app-body">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>

</mat-sidenav-container>
