import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SkeletonLoaderService {
  /** Subject containing all unique keys */
  public uniqeLoaderChanged$ = new BehaviorSubject<string[]>([]);
  /** Global loader subject */
  public globalLoader$ = new BehaviorSubject<boolean>(false);
  /** Unique loader object, each property contains subject */
  private uniqueLoader$: { [key: string]: BehaviorSubject<boolean> } = {};

  constructor() { }

  /**
   * Get uniqiue loader if exists, otherwise throw exception.
   *
   * @param key Unique loader key.
   *
   * @returns BehaviorSubject of requested key.
   *
   * @throws Error with a message that requested key doesn't exists.
   */
  public getUniqueLoader(key: string): BehaviorSubject<boolean> {
    if (key && this.uniqueLoader$[key]) {
      return this.uniqueLoader$[key];
    }

    throw new Error(`[SkeletonLoaderService.getUniqueLoader]: Requested key '${key}' doesn't exist yet.`);
  }

  /**
   * Add the unique loader key and set value.
   *
   * @param key Unique loader key.
   * @param val Value to be set.
   *
   * @throws Error with a message that requested key exists, thus can't be overwritten.
   */
  public addUniqueLoader(key: string, val: boolean = true): void {
    if (this.uniqueLoader$[key]) {
      throw new Error(`[SkeletonLoaderService.addUniqueLoader]: Requested key '${key}' already exists and can't be overwritten.`);
    }

    const prev = this.uniqeLoaderChanged$.value;
    this.uniqueLoader$[key] = new BehaviorSubject<boolean>(val);
    this.uniqeLoaderChanged$.next([...prev, key]);
  }

  /**
   * Remove the unique loader key.
   *
   * @param key Unique loader key.
   *
   * @throws Error with a message that requested key doesn't exists, thus can't be deleted.
   */
  public removeUniqueLoader(key: string): void {
    if (!this.uniqueLoader$[key]) {
      throw new Error(`[SkeletonLoaderService.removeUniqueLoader]: Requested key '${key}' doesn't exists and can't be removed.`);
    }

    const prev = this.uniqeLoaderChanged$.value;
    this.uniqeLoaderChanged$.next(prev.filter(p => p !== key));
    delete this.uniqueLoader$[key];
  }
}
