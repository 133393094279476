import { ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, Directive, ElementRef, ViewContainerRef } from '@angular/core';
import { SkeletonLoaderService } from '@app-services';
import { SkeletonLoaderComponent } from '@app-shared-components/skeleton-loader/skeleton-loader.component';
import { BaseSkeletonLoaderDirective } from './base-skeleton-loader.model';

@Directive({
  selector: '[dadRegularSkeletonLoader]'
})
export class RegularSkeletonLoaderDirective extends BaseSkeletonLoaderDirective {
  private innerText;
  private skeletonParent: HTMLElement;
  private hiddenNodes: Element[] = [];
  private componentRef: ComponentRef<SkeletonLoaderComponent>;

  private get hasChildren(): boolean {
    if (!this.skeletonParent) { return false; }
    return this.skeletonParent.children.length > 0;
  }

  constructor(
    protected el: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected service: SkeletonLoaderService,
  ) {
    super(el, viewContainerRef, componentFactoryResolver, cdr, service);
  }

  show(): void {
    this.skeletonParent = this.el.nativeElement as HTMLElement;
    if (!this.skeletonParent) { return; }
    const skeletonFactory = this.componentFactoryResolver
      .resolveComponentFactory(SkeletonLoaderComponent);

    const computedStyle = this.extractComputedStyles(this.skeletonParent, ['height', 'border-radius', 'width', 'font-size']);

    this.hideParentContent();

    this.componentRef = this.viewContainerRef.createComponent<SkeletonLoaderComponent>(skeletonFactory);

    if (!this.hasChildren) {
      delete computedStyle.height;
      delete computedStyle.width;

      computedStyle.height = computedStyle['font-size'];
    }

    this.componentRef.instance.customNgStyle = computedStyle;
    this.componentRef.instance.customInnerText = this.innerText;

    this.cdr.detectChanges();

    this.skeletonParent.insertBefore(this.componentRef.location.nativeElement, this.skeletonParent.firstChild);
  }

  hide(): void {
    if (!this.skeletonParent) { return; }
    this.showParentContent();

    this.viewContainerRef.clear();
    this.componentRef = null;
  }

  private hideParentContent(): void {
    if (this.hasChildren) {
      for (let i = 0; i < this.skeletonParent.children.length; i++) {
        const e = this.skeletonParent.children.item(i);
        e.classList.add(this.HIDDEN_CLASS);
        this.hiddenNodes.push(e);
      }
    } else {
      this.innerText = this.skeletonParent.innerText;
      this.skeletonParent.innerText = '';
    }
  }

  private showParentContent(): void {
    if (this.hasChildren && this.skeletonParent.children.length > 1) {
      this.hiddenNodes.forEach(e => e.classList.remove(this.HIDDEN_CLASS));
    } else {
      this.skeletonParent.innerText = this.innerText;
    }
  }

}
