import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Asset, AssetState } from './asset.model';
import { Action, createReducer, on } from '@ngrx/store';
import { assetUpsertMany, assetUpsertOne, assetRemoveMany, assetRemoveOne } from './asset.actions';

export const assetAdapter: EntityAdapter<Asset> = createEntityAdapter<Asset>({
  selectId: (a) => a.assetID,
});

const initialState = assetAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(assetUpsertMany, (state: AssetState, { assets }) =>
    assetAdapter.upsertMany(assets, state)
  ),
  on(assetUpsertOne, (state: AssetState, { asset }) =>
    assetAdapter.upsertOne(asset, state)
  ),
  on(assetRemoveMany, (state: AssetState, { assetIds }) =>
    assetAdapter.removeMany(assetIds, state)
  ),
  on(assetRemoveOne, (state: AssetState, { assetId }) =>
    assetAdapter.removeOne(assetId, state)
  )
);

export const assetReducer = (state: AssetState = initialState, action: Action): AssetState => reducer(state, action);
