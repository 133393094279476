import { EntityState } from '@ngrx/entity';
import { Asset } from '../assets/asset.model';

export interface CompanyFirestore {
  address?: {
    country: string;
    code: string;
    zip: string;
    city: string;
    street: string;
    bcc_code?: string; // dev stuff
  };
  companyID: string;
  contactPerson?: {
    email: string;
    name: string;
  };
  location: {
    _latitude: number;
    _longitude: number;
  };
  name: string;
  ownerUID: string;
  ustID: string;
  assets: Asset[];
}

export interface CompanyState extends EntityState<CompanyFirestore> { }
