import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from '@app-models';
import { Observable } from 'rxjs';
import { CoreApiService } from '../http/core-api.service';

@Injectable({ providedIn: 'root' })
export class CompanyHttpService extends CoreApiService {

  private group = 'drip-company/';

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Load all companies from the API.
   *
   * @returns Array of companies.
   */
  public loadAllCompanies(): Observable<Company[]> {
    return this.get(`${this.group}list`);
  }

  /**
   * Load single company from the API.
   *
   * @param id Id of requested company.
   *
   * @returns Company.
   */
  public loadCompany(id: string): Observable<Company> {
    return this.get(`${this.group}get/${id}`);
  }

  public updateCompany(companyID: string, data: Partial<Company>): Observable<Company> {
    return this.put(`${this.group}update/${companyID}`, data);
  }
}
