import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageKey } from '@app-enums';
import { AuthResponse, User } from '@app-models';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class AuthStateService {

  public user$ = new BehaviorSubject<User>(null);
  private tokenChangeSubject = new Subject();

  public refreshInterval;
  private myAccessToken: string;

  constructor(
    @Inject('LOCALSTORAGE') private storage: Storage,
    protected router: Router,

  ) {
    this.initFromStorage();
  }

  /**
   * Get access token
   */
  public get accessToken(): string {
    if (!this.myAccessToken) {
      this.myAccessToken = this.storage.getItem(StorageKey.token);
    }

    return this.myAccessToken;
  }

  /**
   * Set access token
   */
  public set accessToken(token: string) {
    this.storage.setItem(StorageKey.token, token);
    this.myAccessToken = token;
  }

  /**
   * Init user values from storage
   */
  private initFromStorage(): void {

    const user = this.storage.getItem(StorageKey.user);
    if (user) {
      this.user$.next(JSON.parse(user));
    }
  }

  /**
   * Store user data to storage
   *
   * @param response response from login
   */
  public storeUser(response: AuthResponse): void {
    this.user$.next(response.adminbeUser);
    this.storage.setItem(StorageKey.user, JSON.stringify(this.user$.value));
    this.loggedIn = true;
    this.accessToken = response.accessToken;
  }

  /**
   * Get loggedIn value
   */
  get loggedIn(): boolean {
    return this.storage.getItem('loggedIn') === 'true';
  }

  /**
   * Set logged in
   */
  set loggedIn(state: boolean) {
    this.storage.setItem('loggedIn', state.toString());
  }

  /**
   * Logout and remove current user values
   */
  unauthorize(): void {

    this.loggedIn = false;
    this.storage.removeItem('token');
    this.storage.removeItem('user');

    // null values
    this.user$.next(null);
    this.tokenChangeSubject.next(null);

    // remove all (just in case)
    this.storage.clear();
  }

}
