import { createAction, props } from '@ngrx/store';
import { Asset } from './asset.model';

export const assetUpsertMany = createAction(
  '[Asset] Upsert many',
  props<{ assets: Asset[] }>()
);

export const assetUpsertOne = createAction(
  '[Asset] Upsert one',
  props<{ asset: Asset }>()
);

export const assetRemoveMany = createAction(
  '[Asset] Delete many',
  props<{ assetIds: string[] }>()
);

export const assetRemoveOne = createAction(
  '[Asset] Delete one',
  props<{ assetId: string }>()
);

export const getAllAssets = createAction(
  '[Asset] Get all assets',
  props<{ companyId: string }>()
);
