import { AfterViewInit, Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

/**
 * Directive that will change icon for each input html element (FOR NOW) on error.
 * Example:
 *
 * <mat-form-field *ngFor="let field of ['name']">
 *   <mat-label>{{field}}</mat-label>
 *
 *   <input matInput [formControlName]="field" type="text">
 *
 *   <mat-icon dadInvalidFieldIcon [inputControl]="form.get('password')" matSuffix fontSet="fal" fontIcon="fa-eye-slash"></mat-icon>
 *
 *   <mat-error *ngIf="form.get('name').hasError('email') && !form.get('name').hasError('required')">
 *     Please enter a valid email address
 *   </mat-error>
 *   <mat-error *ngIf="form.get('name').hasError('required')">
 *     Email is <strong>required</strong>
 *   </mat-error>
 *
 * </mat-form-field>
 *
 */
@Directive({
  selector: '[dadInvalidFieldIcon]'
})
export class InvalidFieldIconDirective implements OnInit, AfterViewInit, OnDestroy {

  /** Form control element */
  @Input() inputControl: AbstractControl;

  /**
   * Should we keep icon or not, used where icon should be changed as part of the validation,
   * e.g. input button for password toggle visiblity.
   */
  @Input() keepIcon = false;

  /** Invalid set of classes */
  private readonly errorColorClass = 'warn-color';
  private readonly errorIcons = ['fal', 'fa-exclamation-triangle', this.errorColorClass];

  /** Valid set of classes */
  private readonly successColorClass = 'green-color';
  private readonly successIcons = ['fal', 'fa-check-circle', this.successColorClass];

  /** FA icon elements */
  private fontAwesomeIconHtmlElement: HTMLElement;
  private fontAwesesomeDefaultClasses: string[];

  /** Getter which determinates does our form control has any validator (sync and async) */
  private get fieldHasValidators(): boolean {
    const hasSyncValidators = this.inputControl.validator !== null;
    const hasAsyncValidators = this.inputControl.asyncValidator !== null;

    return hasSyncValidators || hasAsyncValidators;
  }

  private unsubscribeAll = new Subject<boolean>();

  constructor(private fontAwesomeIcon: ElementRef<HTMLElement>) { }

  //#region Lifecycle hooks

  ngOnInit(): void {
    this.fontAwesomeIconHtmlElement = this.fontAwesomeIcon.nativeElement;
  }

  ngAfterViewInit(): void {
    this.setDefaultState();
    this.subscribeToChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll.next(true);
    this.unsubscribeAll.complete();
  }

  //#endregion

  //#region Event handling

  /**
   * To toggle default icon with our warning icon, we need to track specific changes.
   * Currently, we're tracking input control's input blur event as well as input control's status change.
   */
  private subscribeToChanges(): void {
    const ev$1 = this.getInputBlurObservable();
    const ev$2 = this.inputControl.statusChanges;

    merge(ev$1, ev$2)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe(this.handleFieldStatusChange);
  }

  /**
   * Get blur event of the input as observable.
   *
   * @returns Observable for blur event.
   */
  private getInputBlurObservable(): Observable<FocusEvent> {
    // in order to get actual element we need to navigate to it
    const matFormFieldElement = this.findMatFormField(this.fontAwesomeIconHtmlElement);

    const input = matFormFieldElement.querySelector('input');

    return fromEvent<FocusEvent>(input, 'blur').pipe(debounceTime(50));
  }

  /**
   * Icon change method.
   *
   * @param status Status change event.
   */
  private handleFieldStatusChange = (status: string | FocusEvent): void => {
    if (status instanceof FocusEvent) {
      if (this.inputControl.status === 'INVALID') {
        this.applyInvalidState();
      }

      return;
    }

    switch (status) {
      case 'INVALID':
        if (this.inputControl.touched) {
          this.applyInvalidState();
        }
        break;
      case 'VALID':
        this.applyValidState();
        break;
    }
  };

  //#endregion

  //#region Icon change methods

  /**
   * Store default state.
   */
  private setDefaultState(): void {
    this.fontAwesesomeDefaultClasses = this.fontAwesomeIconHtmlElement.classList
      .value
      .split(' ')
      .filter(this.acceptOnlyFAClasses);
  }

  /**
   * Error occured.
   */
  private applyInvalidState(): void {
    // if we don't keep icon, we'll change icons
    if (!this.keepIcon) {
      this.fontAwesomeIconHtmlElement.classList.remove(...this.fontAwesesomeDefaultClasses, ...this.successIcons);
      this.fontAwesomeIconHtmlElement.classList.add(...this.errorIcons);
    }

    // either way, we need to update icon color
    this.fontAwesomeIconHtmlElement.classList.remove(this.successColorClass);
    this.fontAwesomeIconHtmlElement.classList.add(this.errorColorClass);
  }

  /**
   * No error at the moment, revert back to the default state.
   */
  private applyValidState(): void {
    // if we don't keep icon, we'll change icons
    if (!this.keepIcon) {
      this.fontAwesomeIconHtmlElement.classList.remove(...this.errorIcons);
      this.fontAwesomeIconHtmlElement.classList.add(...this.fontAwesesomeDefaultClasses);
    }

    // when validator exists we need to add success icons
    if (this.fieldHasValidators && !this.keepIcon) {
      this.fontAwesomeIconHtmlElement.classList.add(...this.successIcons);
    }

    // either way, we need to update icon color
    this.fontAwesomeIconHtmlElement.classList.remove(this.errorColorClass);
    this.fontAwesomeIconHtmlElement.classList.add(this.successColorClass);
  }

  //#endregion

  //#region Utility methods

  /**
   * Seek the parent node mat-form-field for given html element.
   *
   * @param element HTML Element to check if he is mat-form-field.
   *
   * @returns HTML Element of type mat-form-field.
   */
  private findMatFormField(element: HTMLElement): HTMLElement {
    if (element.nodeName.toLocaleLowerCase() === 'mat-form-field') {
      return element;
    }

    return this.findMatFormField(element.parentElement);
  }

  /**
   * Determinate does className belongs to the FA icons.
   *
   * @param className Current class name to check.
   *
   * @returns True if belongs, false otherwise.
   */
  private acceptOnlyFAClasses = (className: string): boolean => {
    let isFaIcon = false;
    const faStyles = [
      'fas', // solid
      'far', // regular
      'fal', // light
      'fad', // duotone
      'fab', // brands
    ];

    // check FA icon style first
    isFaIcon = faStyles.indexOf(className) !== -1;

    if (!isFaIcon) {
      // try with actual FA icon name
      isFaIcon = className.startsWith('fa-');
    }

    return isFaIcon;
  };

  //#endregion
}
