import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CountryState } from './country-state.model';

export const loadCountryStatesByAlpha2 = createAction(
  '[CountryState/API] Load CountryStates by alpha2',
  props<{ alpha2: string }>()
);

export const upsertCountryStateSelectedCountryAlpha2s = createAction(
  '[CountryState/API] Add new country id',
  props<{ alpha2: string }>()
);

export const loadCountryStates = createAction(
  '[CountryState/API] Load CountryStates',
  props<{ countryStates: CountryState[] }>()
);

export const addCountryState = createAction(
  '[CountryState/API] Add CountryState',
  props<{ countryState: CountryState }>()
);

export const upsertCountryState = createAction(
  '[CountryState/API] Upsert CountryState',
  props<{ countryState: CountryState }>()
);

export const addCountryStates = createAction(
  '[CountryState/API] Add CountryStates',
  props<{ countryStates: CountryState[] }>()
);

export const upsertCountryStates = createAction(
  '[CountryState/API] Upsert CountryStates',
  props<{ countryStates: CountryState[] }>()
);

export const updateCountryState = createAction(
  '[CountryState/API] Update CountryState',
  props<{ countryState: Update<CountryState> }>()
);

export const updateCountryStates = createAction(
  '[CountryState/API] Update CountryStates',
  props<{ countryStates: Update<CountryState>[] }>()
);

export const deleteCountryState = createAction(
  '[CountryState/API] Delete CountryState',
  props<{ id: string }>()
);

export const deleteCountryStates = createAction(
  '[CountryState/API] Delete CountryStates',
  props<{ ids: string[] }>()
);

export const clearCountryStates = createAction(
  '[CountryState/API] Clear CountryStates'
);
