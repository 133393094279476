import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Vatlayer } from '@app-models';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { filter, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VatlayerService {
  private readonly api: string;

  constructor(private http: HttpClient) {
    this.api = `${'environment.vatlayer.api'}validate?access_key=${'environment.vatlayer.token'}`;
  }

  /**
   * Provide vatlayer company info for given VAT number.
   *
   * @param vatNumber Requested VAT number.
   *
   * @returns Reposne from the Vatlayer API.
   */
  public get(vatNumber: string, withInvalid = false): Observable<Vatlayer> {
    return this.http
      .get<Vatlayer>(`${this.api}&vat_number=${vatNumber}`)
      .pipe(filter(vat => withInvalid || vat.valid));
    // demo only
    // return of({
    //   company_address: "Wahringerstraße 34\nAT-4030 Linz",
    //   company_name: "D3EIF GmbH",
    //   country_code: "AT",
    //   database: "ok",
    //   format_valid: true,
    //   query: "ATU72641046",
    //   valid: true,
    //   vat_number: "U72641046",
    // });
  }
}
