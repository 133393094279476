import { Action, createReducer, on } from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { CountryState } from './country-state.model';
import * as CountryStateActions from './country-state.actions';

export const countryStatesFeatureKey = 'countryStates';

export interface CountryStateState extends EntityState<CountryState> {
  // additional entities state properties
  loadedCountryAlpha2s: string[];
}

export const adapter: EntityAdapter<CountryState> = createEntityAdapter<CountryState>();

const initialState: CountryStateState = adapter.getInitialState({
  // additional entity state properties
  loadedCountryAlpha2s: [],
});

const reducer = createReducer(
  initialState,
  on(CountryStateActions.addCountryState,
    (state, action) => adapter.addOne(action.countryState, state)
  ),
  on(CountryStateActions.upsertCountryState,
    (state, action) => adapter.upsertOne(action.countryState, state)
  ),
  on(CountryStateActions.addCountryStates,
    (state, action) => adapter.addMany(action.countryStates, state)
  ),
  on(CountryStateActions.upsertCountryStates,
    (state, action) => adapter.upsertMany(action.countryStates, state)
  ),
  on(CountryStateActions.updateCountryState,
    (state, action) => adapter.updateOne(action.countryState, state)
  ),
  on(CountryStateActions.updateCountryStates,
    (state, action) => adapter.updateMany(action.countryStates, state)
  ),
  on(CountryStateActions.deleteCountryState,
    (state, action) => adapter.removeOne(action.id, state)
  ),
  on(CountryStateActions.deleteCountryStates,
    (state, action) => adapter.removeMany(action.ids, state)
  ),
  on(CountryStateActions.loadCountryStates,
    (state, action) => adapter.setAll(action.countryStates, state)
  ),
  on(CountryStateActions.clearCountryStates,
    state => adapter.removeAll(state)
  ),
  on(CountryStateActions.upsertCountryStateSelectedCountryAlpha2s,
    (state, { alpha2 }) => ({
      ...state,
      loadedCountryAlpha2s: state.loadedCountryAlpha2s.includes(alpha2)
        ? [...state.loadedCountryAlpha2s] // we already have this country
        : [...state.loadedCountryAlpha2s, alpha2] // we don't have this country
    }))
);

export const countryStateReducer = (state: CountryStateState = initialState, action: Action): CountryStateState => reducer(state, action);

export const {
  selectIds,
  selectEntities,
  selectAll,
  selectTotal,
} = adapter.getSelectors();
