import {
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  ViewContainerRef
} from '@angular/core';
import { BaseSkeletonLoaderDirective } from './base-skeleton-loader.model';
import { SkeletonLoaderComponent } from '@app-shared-components/skeleton-loader/skeleton-loader.component';
import { SkeletonLoaderService } from '@app-services';

@Directive({
  selector: '[dadFormFieldSkeletonLoader]',
})
export class FormFieldSkeletonLoaderDirective extends BaseSkeletonLoaderDirective {
  private hiddenNodes: Element[] = [];
  private componentRef: ComponentRef<SkeletonLoaderComponent>;

  constructor(
    protected el: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected service: SkeletonLoaderService,
  ) {
    super(el, viewContainerRef, componentFactoryResolver, cdr, service);
  }

  /**
   *
   */
  public show(): void {
    const el = this.el.nativeElement as HTMLElement;

    const skeletonFactory = this.componentFactoryResolver
      .resolveComponentFactory(SkeletonLoaderComponent);

    for (let i = 0; i < el.children.length; i++) {
      const e = el.children.item(i);
      e.classList.add(this.HIDDEN_CLASS);
      this.hiddenNodes.push(e);
    }

    this.componentRef = this.viewContainerRef.createComponent<SkeletonLoaderComponent>(skeletonFactory);
    this.componentRef.instance.customNgClass = this.INPUT_CLASS;

    // MANDATORY, for unknown reason (to me) during component creation (this.viewContainerRef.createComponent)
    // the component style isn't applied at all w/o this line
    this.cdr.detectChanges();

    el.insertBefore(this.componentRef.location.nativeElement, el.firstChild);
  }

  /**
   *
   */
  public hide(): void {
    this.viewContainerRef.clear();
    this.componentRef = null;
    this.hiddenNodes.forEach(e => e.classList.remove(this.HIDDEN_CLASS));
  }

}
