/**
 * Enum for easier way to work with routes.
 */
export enum RouteName {
  login = 'login',

  // modules
  moduleCompanies = 'companies',
  moduleTelematicSystems = 'telematic-systems',
  moduleTeltonika = 'teltonika',

  //#region CRUD
  componentCreate = 'create',
  componentUpdate = 'update',
  //#endregion

  telematicsUpdate = 'update',

  //#region Module companies sub-routes (tabs)
  companyDetailsTab = 'details',
  companyUsersTab = 'users',
  companyTelematicsTab = 'telematics',
  companyAssetsTab = 'assets',
  companyFeatureFlag = 'featureFlag',
  //#endregion
}
