import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthHttpService, SnackBarService } from '../services';
import { catchError } from 'rxjs/operators';
import { LaravelHttpStatusCode } from 'app/shared/enums';
import { Larasponse, ValidationMessage } from 'app/shared/models';
import { LaravelRequestValidationService } from '../services';
import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  /**
   * This property will store all error messages per user request.
   */
  private laravelValidationFields: { [key: string]: Element[] } = {};

  constructor(
    public snackBarService: SnackBarService,
    private laravelRequestValidationService: LaravelRequestValidationService,
    protected router: Router,
    protected authService: AuthHttpService,
  ) { }

  /**
   * The interceptor method.
   *
   * @param req - Current request.
   * @param next - HttpHandler.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.cleanUpPreviousValidationErrors(req);

    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {

        const errorStatus = error.status;

        // clasify by code
        const isClientError = (errorStatus % 400) <= 99;
        const isServerError = (errorStatus % 500) <= 99;

        this.defaultErrorHandler(error);
        // if (isClientError) {
        //   this.handleClientError(error);
        // } else if (isServerError) {
        //   this.handleServerError(error);
        // } else {
        //   this.defaultErrorHandler(error);
        // }
        return throwError(() => error);
        // return of(error);
      })
    );
  }

  /**
   * Will remove errors from html for given request.lead
   *
   * @param req - Current request.
   */
  cleanUpPreviousValidationErrors(req: HttpRequest<any>): void {
    const errors: Element[] = this.laravelValidationFields[req.url];

    if (errors) {
      for (const error of errors) {
        error.innerHTML = '';
      }
    }

    // clean up
    delete this.laravelValidationFields[req.url];
  }

  /**
   * Will simply load notify.
   *
   * @param error - Error occured.
   */
  private defaultErrorHandler(error: HttpErrorResponse) {
    let msg = error.url; // default scenaio

    if (error.error.message) {
      msg = error.error.message;
    }

    this.snackBarService.showErrorMessage(msg);
  }

  /**
   * Handle all server errors.
   *
   * @param error - Error occured.
   */
  private handleServerError(error: HttpErrorResponse) {
    this.defaultErrorHandler(error);
  }

  /**
   * Handle all client errors (4xx).
   *
   * @param error - Error occured.
   */
  private handleClientError(error: HttpErrorResponse) {
    const errorStatus = error.status;

    switch (errorStatus) {
      case LaravelHttpStatusCode.HTTP_UNPROCESSABLE_ENTITY:
        this.handleValidationError(error);
        break;
      case LaravelHttpStatusCode.HTTP_UNAUTHORIZED:
      case LaravelHttpStatusCode.HTTP_FORBIDDEN:
        this.handleAuthError(error);
        break;
      default:
        this.defaultErrorHandler(error);
    }
  }

  /**
   * Token expired error. Method will try to refresh token.
   *
   * @param error - Error occured.
   */
  private handleExpiredToken(error: HttpErrorResponse) {
    // this.authService
    //   .refresh()
    //   .toPromise();
  }

  /**
   * Authorization error.
   *
   * @param error - Error occured.
   */
  handleAuthError(error: HttpErrorResponse) {
    this.snackBarService.showErrorMessage(error.error.errorMessage);
  }

  /**
   * Form Validation error method, here we'll iterate through each validation
   * message in response, find proper mat-error field by id and update HTML value.
   *
   * @param error - Error occured.
   */
  handleValidationError(error: HttpErrorResponse) {
    const response = error.error as Larasponse<any>;

    // parse messages in order to show them in proper mat-error
    for (const prop in response.messages) {
      if (!prop) { // lint stuff
        continue;
      }

      // create message
      const msg: ValidationMessage = {
        name: prop,
        url: error.url,
        messages: response.messages[prop],
      };

      // broadcast message
      this.laravelRequestValidationService.errorOccured(msg);
    }

    // process notification
    const errorMessage = `${response.errorMessage}`;
    this.snackBarService.showErrorMessage(errorMessage);
  }
}
