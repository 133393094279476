<div fxLayout="column" fxLayoutAlign="start strech" fxFill>
  <!-- logo -->
  <div fxFlex="nogrow">
    <div class="nav-icon-wrapper">
      <img src="/assets/img/drip-logo_avatar.png" class="dad-logo" />
    </div>
  </div>

  <!-- modules -->

  <!-- <div fxFlex fxLayout="column" fxLayoutAlign="center stretch" fxLayoutGap="8px">
    <div class="nav-icon-wrapper" *ngFor="let module of (availableModules$|async)">
      <button class="primary-border" mat-flat-button [routerLink]="module" routerLinkActive="nav-active">
        <mat-icon class="tab-icon tw-text-lg" fontSet="far" fontIcon="{{module|routenameToIcon}}">
        </mat-icon>
      </button>
    </div>
  </div> -->
  <div class="tw-flex tw-flex-col tw-items-center tw-justify-center	tw-grow">
    <ng-container *ngFor="let module of (availableModules$ | async)">
      <button mat-flat-button [routerLink]="module" routerLinkActive="nav-active" class="nav-button"
        [matTooltip]="module | routeNameToTitle">
        <mat-icon class="tab-icon tw-text-lg" fontSet="far" fontIcon="{{module|routenameToIcon}}"></mat-icon>
      </button>
    </ng-container>
  </div>

  <!-- logout -->
  <div fxFlex="nogrow">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div class="nav-icon-wrapper">
        <button (click)="onLogout()" class="nav-button" mat-flat-button matTooltip="Click to logout">
          <!-- <mat-icon>logout</mat-icon> -->
          <mat-icon class="tab-icon tw-text-lg" fontSet="fal" fontIcon="fa-sign-out"></mat-icon>
          <!-- <i class="fal "></i> -->
        </button>
      </div>
    </div>
  </div>

</div>
