import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Zipcodebase, ZipcodebaseCountryCodesResponse } from '@app-models';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

/**
 * Third party service used for obtaining company address details.
 */
@Injectable({
  providedIn: 'root'
})
export class ZipcodebaseService {
  private readonly api: string;
  constructor(protected http: HttpClient) {
    this.api = `${'environment.zipCodeBase.api'}`;
  }

  /**
   * Provide info for given country and zip code.
   *
   * @param countryCode Two letter country code.
   * @param zipCode     Zip code information.
   *
   * @returns Information for given country and zip code or null.
   */
  getInfo(countryCode: string, zipCode: string): Observable<Zipcodebase> {
    return this.http
      .get(`${this.api}search?apikey=${'environment.zipCodeBase.token'}&codes=${zipCode}&country=${countryCode}`)
      .pipe(map(this.mapResponseToModel));
  }

  /**
   * Extract our value from the Zipcodebase API response.
   *
   * @param r Response from the Zipcodebase API.
   * @returns Our desired model or null.
   */
  private mapResponseToModel = (r: ZipcodebaseCountryCodesResponse): Zipcodebase => {
    if (!r.results) {
      return null;
    }

    const prop = Object.keys(r.results)[0];

    if (r.results[prop]) {
      return r.results[prop][0];
    }

    return null;
  };
}
