import { Pipe, PipeTransform } from '@angular/core';
import { RouteName } from '@app-enums';

@Pipe({
  name: 'routeNameToTitle'
})
export class RouteNameToTitlePipe implements PipeTransform {

  transform(value: RouteName): string {
    let title = '';
    switch (value) {
      case RouteName.moduleCompanies:
        title = 'Companies';
        break;
      case RouteName.moduleTelematicSystems:
        title = 'Telematic systems';
        break;
      default:
        title = null;
    }

    return title;
  }

}
