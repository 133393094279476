import { AfterViewInit, Component, Input, OnChanges, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'dad-entity-table',
  templateUrl: './entity-table.component.html',
  styleUrls: ['./entity-table.component.scss'],
})
export class EntityTableComponent<T> implements AfterViewInit, OnChanges {
  @Input() tableTitle: string = null;
  @Input() pagination = true;
  @Input() data: T[];
  @Input() columns: TableColumn<T>[];
  @Input() pageSize = 10;
  @Input() rowButtonTemplate: TemplateRef<any>;
  @Input() onRowClick: (row: T) => void;

  public dataSource = new MatTableDataSource<T>();
  public visibleColumns: Array<keyof T | string>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.columns) {
      this.visibleColumns = this.columns.map((column) => column.property);
    }

    if (changes.data) {
      this.dataSource.data = this.data;
    }
  }

  /**
   * Handle onClick from table row and call input method ( if there is one ) with selected item as param.
   *
   * @param selectedRow generic selected item from row
   */
  public onSelectedRowClick(selectedRow: T) {
    if (this.onRowClick) {
      this.onRowClick(selectedRow);
    }
  }
}

export interface TableColumn<T> {
  label: string;
  property: keyof T | string;
  type: 'text' | 'button' | 'html' | 'checkbox'; //  | 'image' | 'badge' | 'progress' | 'checkbox' |
  visible?: boolean;
  html?: TemplateRef<unknown>;
  cssClasses?: string[];
}
