import { createSelector, select } from '@ngrx/store';
import { CountryStateState, selectAll, selectEntities } from './country-state.reducer';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities';

const selectCountryStatesState = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.countryStates,
);

export const selectAllCountryStates = createSelector(selectCountryStatesState, selectAll);
export const selectAllCountryStateEntities = createSelector(selectCountryStatesState, selectEntities);
// export const selectCountryStatesByAlpaha2 = (id: number) => createSelector(selectAll, (cs) => cs.filter(c => c.countryId === id));

// export const selectCountryStatesByAlpaha2 = createSelector(
//   selectAll,
//   (countryStates) => countryStates.filter(cs => cs.countryId === )
// )

export const selectAllSelectedCountryIds = createSelector(
  selectCountryStatesState,
  (state: CountryStateState) => state.loadedCountryAlpha2s
);
