import { EntityState } from '@ngrx/entity';

export interface Country {
  id: number;
  alpha2: string;
  alpha3: string;
  name: string;
  region: string;
  subregion: string;
  states?: Country[];
}

export interface CountryState extends EntityState<Country> {
  selectedCountryId: number;
}
