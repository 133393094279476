import { Actions, createEffect, ofType } from '@ngrx/effects';
import { teltonikaGetAll, teltonikaUpsertMany } from '@app-store-entities';
import { map, mergeMap } from 'rxjs/operators';
import { TeltonikaService } from './teltonika.service';
import { Injectable } from '@angular/core';
import { EMPTY } from 'rxjs';

@Injectable()
export class TeltonikaEffects {

  loadTeltonikas$ = createEffect(() => this.actions$.pipe(
    ofType(teltonikaGetAll),
    mergeMap(({ sortColumn, sortDirection, limit, page, query }) =>
      this.teltonikaService.getAllTeltonikas(sortColumn, sortDirection, limit, page, query).pipe(
        map(teltonikas => teltonikaUpsertMany({ teltonikas }))
      )
    )
  ));

  constructor(private actions$: Actions, private teltonikaService: TeltonikaService) {
  }
}
