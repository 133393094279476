import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Larasponse, AuthToken } from 'app/shared/models';
import { CoreApiService } from '../http/core-api.service';
import { map } from 'rxjs/operators';
import { AuthHttpService } from './auth-http.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService extends CoreApiService {

  constructor(
    public http: HttpClient,
    private authService: AuthHttpService,
  ) {
    super(http);
  }

  public login(userData): Observable<Larasponse<AuthToken>> {
    return this.post<Larasponse<AuthToken>>('auth/login', userData);
  }

  public logout() {
    return this.post('auth/logout');
  }

  public refresh(): void {
    this
      .post<Larasponse<AuthToken>>('auth/refresh')
      .pipe(map(response => {
        const token: AuthToken = response.data;
        // this.authService.saveToken(token);
      }));
  }
}
