import { createSelector } from '@ngrx/store';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities';
import { countryAdapter } from './country.reducer';

const { selectEntities, selectAll } = countryAdapter.getSelectors();

export const selectCountries = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.countries,
);

export const selectAllCountries = createSelector(selectCountries, selectAll);
export const selectCountryEntities = createSelector(selectCountries, selectEntities);

export const selectCountryById = (id: number) => createSelector(selectCountryEntities, countryDictionary => countryDictionary[id]);

export const selectSelectedCountryId = createSelector(selectCountries, state => state.selectedCountryId);
