import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Telematic, TelematicCreateObject } from '@app-models';
import { Observable } from 'rxjs';
import { CoreApiService } from '../http/core-api.service';

@Injectable({ providedIn: 'root' })
export class TelematicHttpService extends CoreApiService {

  private group = 'telematic-system-account/';

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  /**
   * Load all telematic systems available in system.
   *
   * @returns Array of telematic systems.
   */
  public loadAllTelematics(): Observable<Telematic[]> {
    return this.get(`telematic-system/list/`);
  }

  /**
   * Load all telematic systems for specified company.
   *
   * @param companyID Id of company.
   *
   * @returns Array of telematic systems.
   */
  public loadTelematicsForCompany(companyID: string): Observable<Telematic[]> {
    return this.get(`${this.group}list/${companyID}`);
  }

  /**
   * Update of single telematic system.
   *
   * @param telematicSystemAccountId  Id of telematic system of update.
   * @param telematicData             Telematic system's data.
   *
   * @returns Updated telematic system.
   */
  public updateTelematicItem(telematicSystemAccountId: string, telematicData: Partial<Telematic>): Observable<Telematic> {
    return this.put(`${this.group}update/${telematicSystemAccountId}`, telematicData);
  }

  /**
   * Get single telematic by telematic id
   *
   * @param telematicSystemAccountId telematic system id
   * @returns Telematic observable
   */
  public getSingleTelematic(telematicSystemAccountId: string): Observable<Telematic> {
    return this.get(`${this.group}get/${telematicSystemAccountId}`);
  }

  /**
   *
   *
   * @param postObject
   * @returns
   */
  public createTelematicSystemAccount(postObject: TelematicCreateObject): Observable<Telematic> {
    return this.post(`${this.group}create/`, postObject);
  }

}
