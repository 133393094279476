import { createSelector } from '@ngrx/store';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities';
import { companyAdapter } from './company.reducer';

const {
  selectEntities,
  selectAll
} = companyAdapter.getSelectors();

export const selectCompanies = createSelector(
  selectEntitiesState,
  (state: EntitiesState) => state.companies
);

export const selectAllCompanies = createSelector(selectCompanies, selectAll);
export const selectCompanyEntities = createSelector(selectCompanies, selectEntities);

export const selectCompanyById = (id: string) => createSelector(selectCompanyEntities, companyDictionary => companyDictionary[id]);
