import { createSelector } from '@ngrx/store';
import { EntitiesState, selectEntities as selectEntitiesState } from '../entities';
import { teltonikaAdapter } from './teltonika.reducer';

const {
  selectEntities,
  selectAll
} = teltonikaAdapter.getSelectors();

export const selectTeltonikas = createSelector(selectEntitiesState, (state: EntitiesState) => state.teltonikas);
export const selectAllTeltonikas = createSelector(selectTeltonikas, selectAll);
export const selectTeltonikaEntities = createSelector(selectTeltonikas, selectEntities);

export const selectTeltonikaById = (id: string) => createSelector(selectTeltonikaEntities, teltonikaDictionary => teltonikaDictionary[id]);
