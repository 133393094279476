import { createAction, props } from '@ngrx/store';
import { CompanyFirestore } from './company-firestore.model';
import { OrderByDirection } from '@app-models';

export const companyUpsertMany = createAction(
  '[Company] Upsert Many',
  props<{ companies: CompanyFirestore[] }>()
);

export const companyUpsertOne = createAction(
  '[Company] Upsert One',
  props<{ company: CompanyFirestore }>()
);

export const companyDeleteMany = createAction(
  '[Company] Delete Many',
  props<{ ids: string[] }>()
);

export const companyDeleteOne = createAction(
  '[Company] Delete One',
  props<{ id: string }>()
);

export const companyGetAll = createAction(
  '[Company] Get All Companies',
  props<{ sortColumn?: string; sortDirection?: OrderByDirection; limit?: number; query?: string }>() // question: extract to interface?
);
