import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { CompanyFirestore, CompanyState } from './company-firestore.model';
import { Action, createReducer, on } from '@ngrx/store';
import { companyDeleteMany, companyDeleteOne, companyUpsertMany, companyUpsertOne } from './company.actions';
import { assetUpsertMany } from '../assets/asset.actions';
import produce from 'immer';

export const companyAdapter: EntityAdapter<CompanyFirestore> = createEntityAdapter<CompanyFirestore>({
  selectId: (c) => c.companyID,
});

export const initialState = companyAdapter.getInitialState();

export const reducer = createReducer(
  initialState,
  on(companyUpsertMany, (state: CompanyState, { companies }) =>
    companyAdapter.upsertMany(companies, state)
  ),
  on(companyUpsertOne, (state: CompanyState, { company }) =>
    companyAdapter.upsertOne(company, state)
  ),
  on(companyDeleteMany, (state: CompanyState, { ids }) =>
    companyAdapter.removeMany(ids, state)
  ),
  on(companyDeleteOne, (state: CompanyState, { id }) =>
    companyAdapter.removeOne(id, state)
  ),
  on(assetUpsertMany, (state: CompanyState, { assets }) =>
    produce(state, draft => {
      const companyId = assets.map(a => a.companyID).pop();
      const company = draft.entities[companyId];
      company.assets = assets;
    })
  )
);

export const companyReducer = (state: CompanyState = initialState, action: Action): CompanyState => reducer(state, action);
