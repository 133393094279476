import { Injectable } from '@angular/core';
import { Company } from '@app-models';
import { ComponentStore } from '@ngrx/component-store';
import { filter, map, Observable } from 'rxjs';
import { BreadcrumbEntityNameSelector } from '@app-models';

export interface PageUpdateCompanyState {
  selectedCompany: Company;
};

const initialState: PageUpdateCompanyState = {
  selectedCompany: null,
};

@Injectable()
export class PageUpdateCompanyStore extends ComponentStore<PageUpdateCompanyState> implements BreadcrumbEntityNameSelector {
  constructor() {
    super(initialState);
  }

  public getEntityName$: Observable<string> =
    this.select(state => state.selectedCompany)
      .pipe(
        filter(Boolean),
        map(company => company.name)
      );

  public readonly state$: Observable<PageUpdateCompanyState> = this.select(state => state);

  public readonly selectedCompany$: Observable<Company> = this.state$.pipe(map(state => state.selectedCompany), filter(Boolean));

  public readonly updateSelectedCompany = this.updater((state, selectedCompany: Company) => ({
    ...state,
    selectedCompany,
  }));
}
