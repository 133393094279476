import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { companyReducer } from './company/company.reducer';
import { CompanyState } from './company/company-firestore.model';
import { countryReducer } from './country/country.reducer';
import { CountryState } from './country/country.model';
import { AssetState } from './assets/asset.model';
import { assetReducer } from './assets/assets.reducer';
import { countryStateReducer, CountryStateState } from './country-state/country-state.reducer';

import { TeltonikaState } from './teltonika/teltonika.model';
import { teltonikaReducer } from './teltonika/teltonika.reducer';

export const FEATURE_NAME = 'entities';
export const selectEntities = createFeatureSelector<State, EntitiesState>(
  FEATURE_NAME
);

export const reducers: ActionReducerMap<EntitiesState> = {
  companies: companyReducer,
  countries: countryReducer,
  assets: assetReducer,
  countryStates: countryStateReducer,
  teltonikas: teltonikaReducer,
};

export interface EntitiesState {
  countries: CountryState;
  companies: CompanyState;
  assets: AssetState;
  countryStates: CountryStateState;
  teltonikas: TeltonikaState;
}

export interface State {
  entities: EntitiesState;
}
