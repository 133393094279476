import { Injectable } from '@angular/core';
import { AssetService } from './assets.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { assetUpsertMany, getAllAssets } from '@app-store-entities';
import { map, mergeMap } from 'rxjs/operators';

@Injectable()
export class AssetEffects {

  getAllAssets$ = createEffect(() => this.actions$.pipe(
    ofType(getAllAssets),
    mergeMap(({ companyId }) => this.assetService.getAllAssetsByCompanyId(companyId).pipe(
      map(assets => assetUpsertMany({ assets }))
    ))));

  constructor(private assetService: AssetService, private actions$: Actions) {
  }
}
