import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthStateService } from '@app-services';
import { filter, map, Observable } from 'rxjs';
import packageJson from '../../../../../../package.json';

@Component({
  selector: 'dad-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationHeaderComponent {

  public userName$: Observable<string>;
  public version = packageJson.version;

  constructor(private authStateService: AuthStateService) {
    this.userName$ = this.authStateService.user$
      .asObservable()
      .pipe(
        filter(u => !!u),
        map(u => u.email ?? `${u.firstName} ${u.lastName}`),
      );
  }
}
