import { ErrorHandler } from '@angular/core';
import { environment } from 'environments/environment';

export class GlobalErrorHandler implements ErrorHandler {
  constructor() { }

  handleError(error: Error) {

    if (!environment.production) {
      /* eslint-disable no-console */
      console.error(error);
      /* eslint-enable no-console */
    } else {
      // tbd
    }
  }
}
