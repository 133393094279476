<div class="tw-card tw-overflow-auto tw-w-full">
  <div class="tw-border-b tw-py-4 tw-px-6" fxLayout="row" fxLayoutAlign="start center" *ngIf="tableTitle">
    <h2 class="tw-m-0 tw-title" fxFlex="auto">{{ tableTitle }}</h2>
  </div>

  <table [dataSource]="dataSource" class="tw-w-full tw-overflow-auto" mat-table matSort>
    <ng-container *ngFor="let column of columns">
      <!-- type text -->
      <ng-container *ngIf="column.type === 'text'" [matColumnDef]="''+column.property">
        <th *matHeaderCellDef mat-header-cell mat-sort-header [disabled]="data === null || data.length === 0">{{
          column.label }}</th>
        <td *matCellDef="let row" [ngClass]="column.cssClasses" mat-cell>{{ row[column.property] }}</td>
      </ng-container>

      <!-- type button -->
      <ng-container *ngIf="column.type === 'button'" [matColumnDef]="''+column.property">
        <th *matHeaderCellDef mat-header-cell>{{ column.label }}</th>
        <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
          <ng-container *ngTemplateOutlet="rowButtonTemplate; context: { $implicit: row, index: i }"></ng-container>
        </td>
      </ng-container>

      <!-- type html -->
      <ng-container *ngIf="column.type === 'html'" [matColumnDef]="''+column.property">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>{{ column.label }}</th>
        <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
          <ng-container *ngTemplateOutlet="column.html; context: { $implicit: row, index: i }"></ng-container>
        </td>
      </ng-container>

      <!-- type checkbox -->
      <ng-container *ngIf="column.type === 'checkbox'" [matColumnDef]="''+column.property">
        <th *matHeaderCellDef mat-sort-header mat-header-cell>{{ column.label }}</th>
        <td *matCellDef="let row; let i = index" [ngClass]="column.cssClasses" mat-cell>
          <span class="checkbox-wrapper" (click)="$event.stopPropagation(); $event.preventDefault()">
            <mat-checkbox color="primary" disableRipple [checked]="row[column.property]"></mat-checkbox>
          </span>
        </td>
      </ng-container>

    </ng-container>

    <tr *matHeaderRowDef="visibleColumns" mat-header-row></tr>
    <tr *matRowDef="let row; columns: visibleColumns" mat-row (click)="onSelectedRowClick(row)"></tr>

    <tr class="mat-row no-data" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="visibleColumns.length">
        <ng-container *ngIf="data===null; else noData">
          <span>Loading...</span>
        </ng-container>
        <ng-template #noData>No data</ng-template>
      </td>
    </tr>
  </table>

  <mat-paginator [hidden]="!pagination" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 15, 20, 50, 100]"
    showFirstLastButtons class="paginator tw-select-none">
  </mat-paginator>

</div>
