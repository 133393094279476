import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'dad-skeleton-loader',
  template: `<div class="skeleton-box" [ngStyle]="customNgStyle" [ngClass]="customNgClass" [innerText]="customInnerText"></div>`,
  styleUrls: ['./skeleton-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SkeletonLoaderComponent {
  /** Our custom style, same as https://angular.io/api/common/NgStyle */
  @Input() customNgStyle: { [key: string]: string };
  /** Our custom class, same as https://angular.io/api/common/NgClass */
  @Input() customNgClass: string | string[] | Set<string> | { [klass: string]: any };

  @Input() customInnerText = '';
}
