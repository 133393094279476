import { EntityState } from '@ngrx/entity';

export interface Asset {
  assetID: string;
  companyID: string;
  name?: string;
  suggestedName?: string;

  address?: {
    country: string;
    street: string;
  };

}

export interface AssetState extends EntityState<Asset> {}
