import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { countryUpserMany, countryGetAll, selectAllCountries } from '@app-store-entities';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { CountryService } from './country.service';
import { Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';

@Injectable()
export class CountryEffects {
  loadCountries$ = createEffect(() => this.actions$.pipe(
    ofType(countryGetAll),
    withLatestFrom(this.store.select(selectAllCountries)),
    mergeMap((_, len) => {
      if (len > 0) { return EMPTY; }
      return this.service.getCountries().pipe(
        map(countries => countryUpserMany({ countries }))
      );
    })
  ));

  constructor(
    private actions$: Actions,
    private service: CountryService,
    private store: Store<any>,
  ) { }
}
