import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';

import { Teltonika, TeltonikaState } from './teltonika.model';
import { teltonikaUpsertMany, teltonikaUpsertOne, teltonikaDeleteMany, teltonikaDeleteOne } from './teltonika.actions';

export const teltonikaAdapter: EntityAdapter<Teltonika> = createEntityAdapter<Teltonika>({
  selectId: (t) => t.imei,
});

export const teltonikaInitialState = teltonikaAdapter.getInitialState();

const reducer = createReducer(
  teltonikaInitialState,
  on(teltonikaUpsertMany, (state: TeltonikaState, { teltonikas }) =>
    teltonikaAdapter.upsertMany(teltonikas, state)
  ),
  on(teltonikaUpsertOne, (state: TeltonikaState, { teltonika }) =>
    teltonikaAdapter.upsertOne(teltonika, state)
  ),
  on(teltonikaDeleteMany, (state: TeltonikaState, { ids }) =>
    teltonikaAdapter.removeMany(ids, state)
  ),
  on(teltonikaDeleteOne, (state: TeltonikaState, { id }) =>
    teltonikaAdapter.removeOne(id, state)
  ),

);

export const teltonikaReducer = (state: TeltonikaState = teltonikaInitialState, action: Action): TeltonikaState => reducer(state, action);
