<div class="dialog-wrapper">
  <mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start start">
      <div class="popup-type-wrapper">
        <i [ngClass]="popupTypeToClassName|memoize:data.type" class="font-size-48-lh-13"></i>
      </div>
      <div fxFlex fxLayout="column">
        <div class="font-size-24-lh-13 text-uppercase popup-title" [innerHTML]="data.title"></div>
        <div class="font-size-18-lh-13 popup-content" [innerHTML]="data.content"></div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px">
    <button *ngFor="let button of data.buttonsDef" mat-flat-button class="huge-button font-size-24"
      [ngClass]="buttonTypeToClassName|memoize:button.type" [mat-dialog-close]="button.matDialogClose">
      <span fxFlayout="row" fxLayoutAlign="space-between center">
        <i [ngClass]="button.icon"></i>&nbsp;&nbsp;&nbsp;{{button.title}}</span>
    </button>
  </mat-dialog-actions>
</div>
