import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, LoginGuard } from './core/guards';
import { RouteName } from './shared/enums';

const routes: Routes = [
  {
    path: RouteName.login,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: RouteName.moduleCompanies,
    loadChildren: () => import('./modules/companies/companies.module').then(m => m.CompaniesModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouteName.moduleTelematicSystems,
    loadChildren: () => import('./modules/telematic-systems/telematic-systems.module').then(m => m.TelematicSystemsModule),
    canActivate: [AuthGuard]
  },
  {
    path: RouteName.moduleTeltonika,
    loadChildren: () => import('./modules/teltonika/teltonika.module').then(m => m.TeltonikaModule),
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: `/${RouteName.moduleCompanies}`
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
