import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ApplicationNavigationService } from '@app-services';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  // template: `<dad-application-layout *ngIf="!authPage; else loginPage"></dad-application-layout>
  // <ng-template #loginPage><router-outlet></router-outlet></ng-template>`,
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(
    public router: Router,
    private appNavService: ApplicationNavigationService, // we need nav service from the start...
  ) {
    this.fakeAuth();
  }

  public authPage = true;

  /**
   * Just dummy method for better routing.
   */
  private fakeAuth(): void {
    this.router.events
      .pipe(filter(v => v instanceof NavigationEnd))
      .subscribe((v: NavigationEnd) => {
        this.authPage = v.url.includes('login');
      });
  }
}
