import { Component } from '@angular/core';
import { RouteName } from '@app-enums';
import { BehaviorSubject } from 'rxjs';
import { AuthStateService } from '@app-services';

@Component({
  selector: 'dad-application-side-menu',
  templateUrl: './application-side-menu.component.html',
  styleUrls: ['./application-side-menu.component.scss']
})
export class ApplicationSideMenuComponent {

  public availableModules$ = new BehaviorSubject<any>([]);

  constructor(
    public authStateService: AuthStateService,
  ) {
    this.availableModules$.next([
      RouteName.moduleCompanies,
      RouteName.moduleTelematicSystems,
      // RouteName.moduleTeltonika
    ]);
  }

  /**
   * Logout current user and redirect to login page.
   */
  public onLogout(): void {
    // clear all resources
    this.authStateService.unauthorize();

    // after clearing all we can reload
    // so angular router will redirect us to login page
    location.reload();
  }

}
