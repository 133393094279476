import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { ValidationMessage } from 'app/shared/models';

/**
 * Service class used for handling server validation messages.
 */
@Injectable({
  providedIn: 'root'
})
export class LaravelRequestValidationService {
  /* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match */
  /**
   * Delimiter when there are multiple messages for same field.
   */
  public static readonly MESSAGES_DELIMITER = '<br />';

  /**
   * Message.
   */
  private inputValidated = new Subject<ValidationMessage>();

  constructor() { }

  /**
   * Method fired when error is occured (from intreceptor).
   *
   * @param message - Occured validation message.
   */
  public errorOccured(message: ValidationMessage): void {
    this.inputValidated.next(message);
  }

  /**
   * Get fired error.
   */
  public getError(): Observable<ValidationMessage> {
    return this.inputValidated;
  }

  /**
   * TBD...
   */
  public clear() {
    this.inputValidated.next(null);
  }

  /**
   * Method that will be executed each time component receives validation
   * message from server.
   *
   * @param form - Component's form.
   * @param validationMessage - Message itself.
   */
  public handleValidationError(form: FormGroup, validationMessage: ValidationMessage): void {

    form.get(validationMessage.name)  // form field
      .setErrors({                    // set custom message
        validation:
          validationMessage
            .messages
            .join(LaravelRequestValidationService.MESSAGES_DELIMITER)
      });
  }

}
