<mat-toolbar id="app-main-header" class="dad-toolbar dark-gray-background tw-select-none">
  <div class="toolbar-left-side">
    DRIP ADMIN DASHBOARD <sub>v{{version}}</sub>
  </div>
  <span class="toolbar-spacer"></span>
  <span class="toolbar-right-side" fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
    <span class="user-name" fxHide.xs>{{ userName$ | async }}</span>
    <mat-icon>person</mat-icon>
  </span>
</mat-toolbar>
