import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from './angular-material/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';

import { TranslateModule } from '@ngx-translate/core';
import { MemoizePipe, PluckPipe, RoutenameToIconPipe, RouteNameToTitlePipe } from '@app-pipes';
import { SnackbarComponent } from '@app-shared-components/snackbar/snackbar.component';
import { ApplicationLayoutComponent } from '@app-shared-components/layout/application-layout/application-layout.component';
import { ApplicationHeaderComponent } from '@app-shared-components/layout/application-header/application-header.component';
import { ApplicationSideMenuComponent } from '@app-shared-components/layout/application-side-menu/application-side-menu.component';
import { ApplicationBreadcrumbComponent } from '@app-shared-components/layout/application-breadcrumb/application-breadcrumb.component';
import { UploadCoverPhotoComponent } from '@app-shared-components/upload-cover-photo/upload-cover-photo.component';
import { PopupDialogComponent } from '@app-shared-components/popup-dialog/popup-dialog.component';

import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import {
  AutofocusDirective,
  InvalidFieldIconDirective,
  FormFieldSkeletonLoaderDirective,
  RegularSkeletonLoaderDirective,
} from '@app-directives';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SkeletonLoaderComponent } from '@app-shared-components/skeleton-loader/skeleton-loader.component';
import { EntityTableComponent } from '@app-shared-components/entity-table/entity-table.component';
import { ApplicationCardComponent } from '@app-shared-components/layout/application-card/application-card.component';
import { ReactiveComponentModule } from '@ngrx/component';

const sharedComponentProviders = [
  SnackbarComponent,
  ApplicationLayoutComponent,
  ApplicationHeaderComponent,
  ApplicationSideMenuComponent,
  ApplicationBreadcrumbComponent,
  ApplicationCardComponent,
  UploadCoverPhotoComponent,
  PopupDialogComponent,
  SkeletonLoaderComponent,
  EntityTableComponent,
];

const projectPipeProviders = [
  PluckPipe,
  RoutenameToIconPipe,
  MemoizePipe,
  RouteNameToTitlePipe,
];

const projectDirectiveProviders = [
  InvalidFieldIconDirective,
  AutofocusDirective,
  FormFieldSkeletonLoaderDirective,
  RegularSkeletonLoaderDirective,
];

@NgModule({
  declarations: [
    ...sharedComponentProviders,
    ...projectPipeProviders,
    ...projectDirectiveProviders,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AngularMaterialModule.forRoot(),
    TranslateModule,
    FlexLayoutModule,
    // skeleton
    NgxSkeletonLoaderModule.forRoot(),
    ReactiveComponentModule,
  ],
  providers: [
    ...projectPipeProviders,
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'outline' } as MatFormFieldDefaultOptions
    },
    ...projectDirectiveProviders,
    DatePipe,
  ],
  exports: [
    ...sharedComponentProviders,
    ...projectPipeProviders,
    ...projectDirectiveProviders,
    AngularMaterialModule,
    TranslateModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FormsModule,
    NgxSkeletonLoaderModule,
    ReactiveComponentModule,
  ]
})
/**
 * The SharedModule is where any shared components, pipes/filters and services should go.
 * The SharedModule can be imported in any other module when those items will be re-used.
 * The shared module shouldn’t have any dependency to the rest of the application and
 * should therefore not rely on any other module.
 */
export class SharedModule { }
