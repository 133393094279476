import {
  AfterViewInit,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { SkeletonLoaderService } from '@app-services';
import { BehaviorSubject, merge, of, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseSkeletonLoaderDirective implements AfterViewInit, OnDestroy {
  public readonly HIDDEN_CLASS = 'skeleton-hidden';
  public readonly INPUT_CLASS = 'skeleton-input';

  @Input() loaderName: string = null;

  // @Input('clickEvent') clickEvent;

  // @HostListener('click', ['$event, $event.target'])
  // onClick(event, targetElement) {
  //   console.log('b');
  //   event.stopPropagation();
  //   event.preventDefault();
  //   event.cancelBubble = true;
  //   event.stopImmediatePropagation();
  // }

  private unsubscribe$ = new Subject<boolean>();
  private subs$: Subscription;

  constructor(
    protected el: ElementRef,
    protected viewContainerRef: ViewContainerRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected cdr: ChangeDetectorRef,
    protected service: SkeletonLoaderService,
  ) { }

  //#region Life cycle hooks

  ngAfterViewInit(): void {
    this.subscribeToLoaderChanges();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }

  //#endregion

  /**
   * Method responsible for revealing of the skeleton loader.
   */
  abstract show(): void;

  /**
   * Method responsible for concealing of the skeleton loader.
   */
  abstract hide(): void;

  /**
   * Listen to all changes in the service's
   */
  private subscribeToLoaderChanges(): void {
    this.service
      .uniqeLoaderChanged$
      .pipe(
        filter(uniqueKeys => uniqueKeys.length === 0 || uniqueKeys.indexOf(this.loaderName) !== -1)
      ).subscribe(this.handleLoadChange);
  }

  /**
   * Will handle actual events from global or unique service loader and act accordingly.
   */
  private handleLoadChange = (): void => {
    // clear subscription
    if (this.subs$) { this.subs$.unsubscribe(); }

    // prepare unique if there is any
    let uniqueSub$ = of(null) as BehaviorSubject<boolean>;
    try {
      uniqueSub$ = this.service.getUniqueLoader(this.loaderName);
    } catch (e) { }

    // listen for changes
    this.subs$ = merge(
      this.service.globalLoader$,
      uniqueSub$,
    ).pipe(
      filter(isVisible => isVisible !== null), // filter non existing unique of(null)
      takeUntil(this.unsubscribe$)
    ).subscribe(isVisible => isVisible ? this.show() : this.hide());
  };

  /**
   * Get styles of given element.
   *
   * @param element HTMLElement used for skeleton loader.
   * @param styles  Array of css properties that will be extracted from our element.
   *
   * @returns Object containing all computed elements form styles array.
   */
  protected extractComputedStyles(element: HTMLElement, styles: string[]): { [style: string]: string } {
    const output = {};

    const computedStyle = window.getComputedStyle(element);

    for (const styleName of styles) {
      output[styleName] = computedStyle[styleName];
    }

    return output;
  }
}
