import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UploadPhotoInvalidType } from '@app-enums';
import { CoverPhoto, Zenserp } from '@app-models';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'dad-upload-cover-photo',
  templateUrl: './upload-cover-photo.component.html',
  styleUrls: ['./upload-cover-photo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadCoverPhotoComponent implements OnChanges {
  @Output() invalidFileEvent = new EventEmitter<UploadPhotoInvalidType>();
  @Output() validFileEvent = new EventEmitter<File>();
  @Input() photo: string = null;
  @Input() suggestedPhotos: Zenserp[];

  @ViewChild('inputFileUpload') inputFileUpload: ElementRef;

  public imgs$ = new BehaviorSubject<CoverPhoto[]>([]);

  private defaultUploadPhoto: CoverPhoto;
  public uploadedPhoto$ = new BehaviorSubject<CoverPhoto>(null);

  private readonly maxFileSize = 3 * (1024 ** 2); // 3mb
  private readonly allowedTypes = ['image/png', 'image/jpeg'];

  private suggestedPhotosCurrentIndex = 0;

  constructor() {
    this.init();
  }

  /**
   * Set default values.
   */
  private init(): void {
    this.defaultUploadPhoto = {
      isDefault: true,
      isFontAwesome: true,
      tooltip: 'Upload png or jpg image with max size 3mb.',
    };
    this.imgs$.next([this.defaultUploadPhoto]);
    this.uploadedPhoto$.next(this.defaultUploadPhoto);
  }

  //#region Life cycle hooks

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.photo && changes.photo.firstChange) {
      this.inputPhotoChanged();
    }

    if (changes.suggestedPhotos) {
      this.inputSuggestedPhotosChanged();
    }
  }

  //#endregion

  /**
   * Input param photo is changed.
   */
  private inputPhotoChanged(): void {
    const photo = { src: this.photo, isDefault: true, isFontAwesome: false } as CoverPhoto;

    this.imgs$.value[0] = photo;
    this.uploadedPhoto$.next(photo);
  }

  /**
   * Input param suggestedPhotos is changed. Here, we need to convert suggested photos array to
   * our array of CoverPhotos, where we'll allways keep first cover photo (default one),
   * which could be fontawesome icon or actual user photo.
   */
  private inputSuggestedPhotosChanged(): void {
    const newImages = this.suggestedPhotos.map((img: Zenserp, index: number) => {
      const parsedImg = {
        isFontAwesome: false,
        src: img.thumbnail,
        isDefault: false,
        tooltip: `${img.title} photo ${index + 1}/${this.suggestedPhotos.length}`
      } as CoverPhoto;

      return parsedImg;
    });

    this.imgs$.next([this.defaultUploadPhoto, ...newImages]);
    // set default again
    this.uploadedPhoto$.next(this.imgs$.value[0]);
  }

  //#region UI events

  /**
   * Open browser's file upload dialog.
   */
  public async onUploadPhoto() {
    if (this.suggestedPhotosCurrentIndex === 0) {
      this.inputFileUpload.nativeElement.click();
    }
  }

  /**
   * On each closed browser's file upload dialog, this method will validate selected files.
   *
   * @param event Selected file event.
   */
  public async onUploadedChange(event: Event) {
    const files = (event.target as (EventTarget & { files: FileList })) // TS type safe
      .files as FileList;

    this.validateUploadedFiles(files) && this.processValidFile(files.item(0));
  }

  public async onNextSuggestedPhoto() {
    if (++this.suggestedPhotosCurrentIndex === this.imgs$.value.length) {
      this.suggestedPhotosCurrentIndex = 0;
    }

    this.updateSuggestedPhotoOnNavButtons();
  }

  public async onPrevSuggestedPhoto() {
    if (--this.suggestedPhotosCurrentIndex === -1) {
      this.suggestedPhotosCurrentIndex = this.imgs$.value.length - 1;
    }

    this.updateSuggestedPhotoOnNavButtons();
  }

  private updateSuggestedPhotoOnNavButtons(): void {
    this.uploadedPhoto$.next(this.imgs$.value[this.suggestedPhotosCurrentIndex]);
  }

  //#endregion

  /**
   * Method will validate uploaded file(s).
   * Currently, we only validate file size. If file is invalid, component will emit an invalidFileEvent.
   *
   * @param files FileList with all uploaded files - we only have one file
   *
   * @returns True if file is valid, false otherwise.
   */
  private validateUploadedFiles(files: FileList): boolean {
    // no files has been selected, leave
    if (files.length === 0) {
      return;
    }

    if (files.length !== 1) {
      this.invalidFileEvent.emit(UploadPhotoInvalidType.moreFilesThanAcceptable);
      return false;
    }

    const file = files.item(0);

    const validSize = file.size <= this.maxFileSize;
    !validSize && this.invalidFileEvent.emit(UploadPhotoInvalidType.fileSize);

    const validType = this.allowedTypes.indexOf(file.type) !== -1;
    !validType && this.invalidFileEvent.emit(UploadPhotoInvalidType.fileType);

    return validSize && validType;
  }

  /**
   * If file is valid, uploaded photo will become cover photo and fileUploadedEvent will be emited.
   * This method also could thorw an invalidFileEvent, in case FileReader couldn't load uploaded file.
   *
   * @param file Validated file.
   */
  private processValidFile(file: File): void {
    this.invalidFileEvent.emit(UploadPhotoInvalidType.clearError);

    const fr = new FileReader();

    fr.onload = (f) => {
      const photo = {
        src: f.target.result as string,
        isDefault: true, isFontAwesome: false,
        tooltip: this.defaultUploadPhoto.tooltip,
      } as CoverPhoto;

      this.imgs$.value[0] = photo;

      this.uploadedPhoto$.next(photo);
      this.validFileEvent.emit(file);
    };

    fr.onerror = (f) => {
      this.invalidFileEvent.emit(UploadPhotoInvalidType.loadError);
    };

    fr.readAsDataURL(file);
  }

}
