import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackStatus } from '../../../shared/enums';
import { SnackbarComponent } from '../../../shared/components/snackbar/snackbar.component';
import { first } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
/**
 * Wrapper around Material's snackbar component.
 */
export class SnackBarService {
  constructor(
    private snackBar: MatSnackBar) { }

  /**
   * Show success snack bar.
   *
   * @param message - Actual message.
   * @param callback - Callback function, when snackbar is closed manually.
   * @param duration - Snackbar duration.
   */
  public showSuccessMessage(message: string, duration = environment.snackbarTimeDuraiton, callback: () => void = null): void {
    const snackRef = this.openSnack(message, SnackStatus.success, duration);

    this.handleAction(snackRef, callback);
  }

  /**
   * Show error snack bar.
   *
   * @param message - Actual message.
   * @param callback - Callback function, when snackbar is closed manually.
   * @param duration - Snackbar duration.
   */
  public showErrorMessage(message: string, duration = environment.snackbarTimeDuraiton, callback: () => void = null): void {
    const snackRef = this.openSnack(message, SnackStatus.error, duration);

    this.handleAction(snackRef, callback);
  }

  /**
   * Show warn snack bar.
   *
   * @param message - Actual message.
   * @param callback - Callback function, when snackbar is closed manually.
   * @param duration - Snackbar duration.
   */
  public showWarningMessage(message: string, duration = environment.snackbarTimeDuraiton, callback: () => void = null): void {
    const snackRef = this.openSnack(message, SnackStatus.warn, duration);

    this.handleAction(snackRef, callback);
  }

  /**
   * Open our snack bar and return it.
   * Here we'll prepare action button (from translate service) and
   * append additional style clases according to type of snackbar.
   *
   * @param message - Actual message.
   * @param type - Type of snackbar.
   * @param duration - Snackbar duration.
   * @returns Opened snack bar.
   */
  private openSnack(message: string, type: SnackStatus, duration: number): MatSnackBarRef<SnackbarComponent> {
    const panelClass = [];

    // prepare action text and panel class
    switch (type) {
      case SnackStatus.success:
        panelClass.push(...['snackbar-success']);
        break;
      case SnackStatus.warn:
        panelClass.push(...['snackbar-warning']);
        break;
      default:
        panelClass.push(...['snackbar-error']);
    }

    // create config instance
    const config = {
      data: message,
      duration,
      panelClass,
    } as MatSnackBarConfig;

    // open snackbar and return ref
    return this.snackBar
      .openFromComponent(SnackbarComponent, config);
  }

  /**
   * Sometimes there has to be user action on snackbar closing,
   * so we'll cover that scenraio in this method.
   * But, if null is passed as callback, we'll skip everything.
   *
   * @param snackRef - Opened snackbar instance.
   * @param next - Callback function.
   */
  private handleAction(snackRef: MatSnackBarRef<SnackbarComponent>, next: () => void = null): void {
    if (!next) {
      return;
    }

    snackRef
      .onAction()
      .pipe(first())
      .subscribe(next);
  }
}
