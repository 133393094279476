import { Pipe, PipeTransform } from '@angular/core';
import { RouteName } from '@app-enums';

@Pipe({
  name: 'routenameToIcon'
})
export class RoutenameToIconPipe implements PipeTransform {

  transform(value: RouteName, ...args: unknown[]): unknown {
    switch (value) {
      case RouteName.moduleCompanies:
        return 'fa-building';
      case RouteName.moduleTelematicSystems:
        return 'fa-digital-tachograph';
      case RouteName.moduleTeltonika:
        return 'fa-microchip';
    }
    return null;
  }

}
