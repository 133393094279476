import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

type BackgroundColorClassType = 'light-gray-background' | 'dark-gray-background';

@Component({
  selector: 'dad-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationCardComponent {

  @Input('background-color') backgroundClass: BackgroundColorClassType = 'light-gray-background';

}
