import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthResponse, User } from '@app-models';
import { Observable } from 'rxjs';
import { CoreApiService } from '../http/core-api.service';

@Injectable({ providedIn: 'root' })
export class AuthHttpService extends CoreApiService {
  private group = 'adminbe/auth/';
  private dripAuth = 'drip-auth/';

  constructor(
    protected http: HttpClient,
  ) {
    super(http);
  }

  /**
   * User authentication method.
   *
   * @param data User details.
   *
   * @returns Authentication response.
   */
  public authenticate(data: Partial<User>): Observable<AuthResponse> {
    return this.post(`${this.group}login`, data);
  }

  public refreshToken(data: { refreshToken: string; userEmail: string }): Observable<AuthResponse> {
    return this.post(`${this.group}refreshToken`, data);
  }

  /**
   * Method which provide token for user in order to enable custom login for drip platform
   *
   * @param id user id, in this case company owner id
   * @returns token string
   */
  public customLoginToken(id: string): Observable<{customLoginToken: string}> {
    return this.get(`${this.dripAuth}getCustomLoginToken/${id}`);
  }
}
